<template>
  <div
    v-if="loadingData"
    class="loading-spin"
    style="height: 650px; background-color: white"
  >
    <a-spin />
  </div>
  <div v-else>
    <payment-info
      style="overflow-y: auto; height: 600px"
      :payment-id.sync="openedPaymentId"
    />

    <a-modal
      title="Confirm Shift"
      v-model="visibleConfirmationModal"
      width="800px"
      :footer="null"
      @cancel="closeConfirmationModal"
    >
      <shift-confirm-adjust
        :key="shiftConfirmationComponentKey"
        :shift="shift"
        :authorizations="authorizations"
        @add="saveShiftAnyway"
        @cancel="closeConfirmationModal"
      />
    </a-modal>
    <a-modal
      :title="
        newShift
          ? 'Add Attachments to New Shift'
          : 'Add Attachments to Shift #' + shift.id
      "
      :footer="null"
      v-model="addAttachmentsVisible"
    >
      <shift-attachments-upload
        :key="shiftAttachmentsKey"
        :shift-id="shift.id"
        :attachments-list="attachmentsFileIds"
        :new-shift="newShift"
        @document-uploaded="documentUploaded"
        @attachments-saved="saveAttachmentsFileIds"
      />
    </a-modal>
    <a-modal
      v-if="!newShift"
      :title="'Attachments of Shift #' + shift.id"
      :footer="null"
      v-model="attachmentsVisible"
      @cancel="closeAttachments"
    >
      <a-table
        :columns="shiftAttachmentsColumns"
        :data-source="shiftAttachments"
        :pagination="false"
        size="small"
      >
        <span slot="actions" slot-scope="text, record">
          <a-button icon="download" @click="openDocumentOnNewPage(record)"
            >Download</a-button
          >
        </span>
      </a-table>
    </a-modal>
    <div v-if="showAssociateShiftHeader" class="associate-shift-header">
      <h3 style="margin: 0 !important">
        {{ associate.firstName }} {{ associate.lastName }}'s
        <template v-if="shift.id"> Shift #{{ shift.id }} </template>
        <template v-else> New Shift </template>
      </h3>
    </div>
    <div class="content-view">
      <div class="content-view-block" id="signed-work-time">
        <div v-if="showAssociateSelector" class="associate-buttons">
          <a-select
            class="associate-select"
            ref="associateSelect"
            show-search
            :allowClear="true"
            placeholder="Associate"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :dropdown-match-select-width="false"
            :default-active-first-option="false"
            :options="foundAssociates"
            :loading="loadingAssociates"
            @search="fetchAssociates"
            @change="handleSearchAssociatesChange"
            @focus="handleFocusAssociates"
          />
          <a-button @click="addNewAssociate">Add New</a-button>
        </div>
        <div class="flex flex-column gap-2">
          <a-button
            v-if="!newShift"
            icon="dollar"
            :disabled="paymentUnavailable"
            @click="openPaymentRequest"
          >
            Payment
          </a-button>
          <div class="attachments-buttons">
            <a-button
              icon="plus"
              :disabled="!associate.number"
              @click="openAddAttachments"
            >
              Add Attachments
            </a-button>
            <a-button
              icon="file-image"
              :disabled="noAttachments"
              @click="openAttachments"
            >
              Attachments
            </a-button>
          </div>
        </div>
        <div v-if="!newShift" class="flex gap-2 mt-4">
          <a-switch v-model="setScheduledAndActualAsSigned" />
          <span>Set Scheduled and Actual As Signed</span>
        </div>
        <h3 class="content-subheader">Signed Work Time</h3>
        <div>
          <p>
            <b>Start</b>
            <a-date-picker
              class="flex-1"
              v-model="shift.signed.start"
              :show-time="{ format: 'h:mm a', use12Hours: true }"
              :format="dateTimeFormat"
              placeholder="Select Start"
              :disabled-date="disabledSignedStartDate"
              :disabled-time="disabledSignedStartTime"
              @change="handleChangeSignedStart"
            />
          </p>
          <p>
            <b>End</b>
            <a-date-picker
              class="flex-1"
              v-model="shift.signed.end"
              :show-time="{ format: 'h:mm a', use12Hours: true }"
              :format="dateTimeFormat"
              placeholder="Select End"
              :disabled="isSignedEndDisabled"
              :disabled-date="disabledSignedEndDate"
              :disabled-time="disabledSignedEndTime"
              @change="handleChangeSignedEnd"
            />
          </p>
          <p>
            <b></b>
            <span class="view-button-group">
              <template
                v-if="
                  !disabledWorkTimeButton || !disabledFillEstimatedLunchesButton
                "
              >
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item
                      key="1"
                      :disabled="disabledWorkTimeButton"
                      @click="fillWorkTime"
                    >
                      Work Time
                    </a-menu-item>
                    <a-menu-item
                      key="2"
                      :disabled="disabledFillEstimatedLunchesButton"
                      @click="fillEstimatedLunches"
                    >
                      Lunches
                    </a-menu-item>
                  </a-menu>
                  <a-button type="primary">
                    Autofill <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </template>
              <a-button
                :disabled="isSignedTimeUnfilled"
                @click="clearSignedTime"
                >Clear</a-button
              >
            </span>
          </p>
        </div>

        <template>
          <h3 class="content-subheader">First Lunch Time</h3>
          <div>
            <template v-if="!isFirstLunchWaived">
              <p>
                <b>Start</b>
                <a-date-picker
                  class="flex-1"
                  v-model="shift.signed.firstLunchStart"
                  :show-time="{ format: 'h:mm a', use12Hours: true }"
                  :format="dateTimeFormat"
                  placeholder="Select Start"
                  :disabled="isLunchTimeDisabled"
                  :disabled-date="disabledFirstLunchStartDate"
                  :disabled-time="disabledSignedLunchStartTime"
                  @change="handleFirstLunchTimeInput"
                  @openChange="setDefaultFirstLunchStartValue"
                />
              </p>
              <p>
                <b>End</b>
                <a-date-picker
                  class="flex-1"
                  v-model="shift.signed.firstLunchEnd"
                  :show-time="{ format: 'h:mm a', use12Hours: true }"
                  :format="dateTimeFormat"
                  placeholder="Select End"
                  :disabled="isLunchEndDisabled"
                  :disabled-date="disabledFirstLunchEndDate"
                  :disabled-time="disabledSignedLunchEndTime"
                  @change="handleFirstLunchTimeInput"
                  @openChange="setDefaultFirstLunchEndValue"
                />
              </p>
              <p>
                <b></b>
                <a-button
                  :disabled="partiallyUnfilledFirstLunchData"
                  @click="clearFirstLunch"
                  >Clear</a-button
                >
              </p>
            </template>
            <div class="flex flex-column gap-2">
              <div class="flex justify-content-between">
                <p class="white-space-nowrap font-bold m-0">1st Lunch Waive</p>
                <a-switch
                  v-model="isFirstLunchWaived"
                  :disabled="
                    !associate.isLunchWaiver || !isFirstLunchWaiveAvailable
                  "
                />
              </div>
              <a-tag
                color="blue"
                class="m-0 white-space-normal text-left"
                v-for="message in firstLunchWaiveInfo"
                :key="message"
              >
                <a-icon type="info-circle" />
                {{ message }}
              </a-tag>
            </div>
          </div>

          <h3 class="content-subheader">Second Lunch Time</h3>
          <div>
            <template v-if="!isSecondLunchWaived">
              <p>
                <b>Start</b>
                <a-date-picker
                  class="flex-1"
                  v-model="shift.signed.secondLunchStart"
                  :show-time="{ format: 'h:mm a', use12Hours: true }"
                  :format="dateTimeFormat"
                  placeholder="Select Start"
                  :disabled="isSecondLunchTimeDisabled"
                  :disabled-date="disabledSecondLunchStartDate"
                  :disabled-time="disabledSignedSecondLunchStartTime"
                  @change="handleSecondLunchTimeInput"
                  @openChange="setDefaultSecondLunchStartValue"
                />
              </p>
              <p>
                <b>End</b>
                <a-date-picker
                  class="flex-1"
                  v-model="shift.signed.secondLunchEnd"
                  :show-time="{ format: 'h:mm a', use12Hours: true }"
                  :format="dateTimeFormat"
                  placeholder="Select End"
                  :disabled="isSecondLunchEndDisabled"
                  :disabled-date="disabledSecondLunchEndDate"
                  :disabled-time="disabledSignedSecondLunchEndTime"
                  @change="handleSecondLunchTimeInput"
                  @openChange="setDefaultSecondLunchEndValue"
                />
              </p>
              <p>
                <b></b>
                <a-button
                  :disabled="partiallyUnfilledSecondLunchData"
                  @click="clearSecondLunch"
                  >Clear</a-button
                >
              </p>
            </template>
            <div class="flex flex-column gap-2">
              <div class="flex justify-content-between">
                <p class="white-space-nowrap font-bold m-0">2nd Lunch Waive</p>
                <a-switch
                  v-model="isSecondLunchWaived"
                  :disabled="
                    !associate.isLunchWaiver || !isSecondLunchWaiveAvailable
                  "
                />
              </div>
              <a-tag
                color="blue"
                class="m-0 white-space-normal text-left"
                v-for="message in secondLunchWaiveInfo"
                :key="message"
              >
                <a-icon type="info-circle" />
                {{ message }}
              </a-tag>
            </div>
          </div>
        </template>
      </div>
      <div class="content-view-block" id="shift-times">
        <h3 class="content-subheader">Shift Times</h3>
        <div class="current-values-block">
          <a-card
            :tab-list="tabList"
            :active-tab-key="activeTab"
            @tabChange="(key) => onTabChange(key, 'activeTab')"
          >
            <template v-if="showScheduled">
              <p>
                <b>Date:</b>
                {{ formattedScheduledDate }}
              </p>
              <p>
                <b>Time:</b>
                {{ formattedScheduledTime }}
              </p>
              <p>
                <b>Duration:</b>
                {{ formattedScheduledDuration ?? "-" }}
              </p>
            </template>
            <template v-else-if="showActual">
              <p>
                <b>Date:</b>
                {{ formattedActualDate }}
              </p>
              <p>
                <b>Time:</b>
                {{ formattedActualTime }}
              </p>
              <p>
                <b>Duration:</b>
                {{ formattedActualDuration ?? "-" }}
              </p>
              <p>
                <b>Lunch:</b>
                {{ formattedActualFirstLunchTime }}
              </p>
              <p>
                <b>Lunch Duration:</b>
                {{ formattedActualFirstLunchDuration ?? "-" }}
              </p>
              <p>
                <b>Second Lunch:</b>
                {{ formattedActualSecondLunchTime }}
              </p>
              <p>
                <b>Second Lunch Duration:</b>
                {{ formattedActualSecondLunchDuration ?? "-" }}
              </p>
              <p>
                <b>Work Hours</b>
                {{ formattedActualWorkHours }}
              </p>
              <p>
                <b>Pay Hours</b>
                {{ formattedActualPayHours }}
              </p>
            </template>
            <template v-else-if="showSigned">
              <p>
                <b>Date:</b>
                {{ formattedSignedDate }}
              </p>
              <p>
                <b>Time:</b>
                {{ formattedSignedTime }}
              </p>
              <p>
                <b>Duration:</b>
                {{ formattedSignedDuration ?? "-" }}
              </p>
              <p>
                <b>Lunch:</b>
                {{ formattedSignedFirstLunchTime }}
              </p>
              <p>
                <b>Lunch Duration:</b>
                {{ formattedSignedFirstLunchDuration ?? "-" }}
              </p>
              <p>
                <b>Second Lunch:</b>
                {{ formattedSignedSecondLunchTime }}
              </p>
              <p>
                <b>Second Lunch Duration:</b>
                {{ formattedSignedSecondLunchDuration ?? "-" }}
              </p>
              <p>
                <b>Work Hours</b>
                {{ formattedWorkHours }}
              </p>
              <p>
                <b>Pay Hours</b>
                {{ formattedPayHours }}
              </p>
            </template>
          </a-card>
        </div>
        <h3 class="content-subheader">Signature</h3>

        <signature
          v-if="shift.signature"
          :associate="signatureAssociate"
          :available-signature="shift.signature"
        />

        <span v-else>Signature not provided</span>
      </div>
    </div>
    <div class="associate-shift-controls">
      <div class="view-button-group">
        <a-button
          type="primary"
          @click="initiateShiftSave"
          :disabled="disabledSaveButton"
          :loading="loadingData"
        >
          {{ shift.id ? "Save" : "Add" }}
        </a-button>
        <a-button
          v-if="!newShift"
          type="danger"
          :disabled="newShift"
          @click="deleteShift"
        >
          Delete
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Card,
  notification,
  Radio,
  DatePicker,
  Dropdown,
  Menu,
  Icon,
  Modal,
  Table,
  Select,
  Switch,
  Tooltip,
  Tag,
  Spin,
} from "ant-design-vue";
import api from "@/api";

import shiftsHelper from "@/helpers/shifts";
import Util from "@/util";

import moment from "moment-timezone";

import MomentDatePicker from "@/components/moment-date-picker.vue";
import ShiftConfirmAdjust from "@/components/shift-confirm-adjust.vue";
import ShiftAttachmentsUpload from "@/components/shift-attachments-upload.vue";
import Signature from "@/components/signature.vue";
import PaymentInfo from "@/modules/payment/components/payment-info.vue";

const DEFAULT_WORK_DURATION_IN_MINUTES = 510;

export default {
  components: {
    MomentDatePicker,
    "a-card": Card,
    "a-date-picker": DatePicker,
    "a-radio-group": Radio.Group,
    "a-radio-button": Radio.Button,
    "a-button": Button,
    "a-dropdown": Dropdown,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "a-modal": Modal,
    "a-table": Table,
    "a-select": Select,
    "shift-confirm-adjust": ShiftConfirmAdjust,
    "shift-attachments-upload": ShiftAttachmentsUpload,
    "a-switch": Switch,
    "a-tooltip": Tooltip,
    "a-tag": Tag,
    "a-spin": Spin,
    "payment-info": PaymentInfo,
    signature: Signature,
  },
  mixins: [api, shiftsHelper],
  props: ["shiftId", "associateNumber", "multipleShiftView"],
  data() {
    return {
      foundAssociates: [],
      loadingAssociates: false,
      searchUsersTimeout: undefined,

      associate: {
        number: undefined,
        firstName: undefined,
        lastName: undefined,
        isLunchWaiver: false,
      },

      shift: {
        id: undefined,
        scheduled: {
          start: undefined,
          end: undefined,
        },
        actual: {
          start: undefined,
          end: undefined,

          firstLunchStart: undefined,
          firstLunchEnd: undefined,

          secondLunchStart: undefined,
          secondLunchEnd: undefined,
        },
        signed: {
          start: undefined,
          end: undefined,

          firstLunchStart: undefined,
          firstLunchEnd: undefined,

          secondLunchStart: undefined,
          secondLunchEnd: undefined,
        },
      },

      signedTimePickerOpen: false,
      setScheduledManually: false,
      loadingData: false,

      shiftConfirmationComponentKey: 0,
      visibleConfirmationModal: false,
      visibleConfirmShiftAdjustmentModal: false,
      authorizations: [],
      shiftAttachments: [],
      saveWithAuths: false,

      addAttachmentsVisible: false,
      attachmentsVisible: false,

      shiftAttachmentsColumns: [
        {
          title: "Name",
          dataIndex: "file_name",
          key: "file_name",
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],

      tabList: [
        {
          key: "signed",
          tab: "Signed",
        },
        {
          key: "actual",
          tab: "Actual",
        },
        {
          key: "scheduled",
          tab: "Scheduled",
        },
      ],
      activeTab: "signed",

      autoSign: false,
      version: undefined,

      attachmentsFileIds: undefined,
      shiftAttachmentsKey: 0,

      setScheduledAndActualAsSigned: false,

      associatedPaymentRequestId: undefined,

      openedPaymentId: undefined,

      isFirstLunchWaived: false,
      isSecondLunchWaived: false,

      signatureId: undefined,
    };
  },
  watch: {
    shift: {
      handler() {
        this.changed = true;
        if (!this.shift.signed.start) {
          this.shift.signed.end = undefined;
        }

        if (!this.shift.signed.firstLunchStart) {
          this.shift.signed.firstLunchEnd = undefined;
        }

        if (!this.shift.signed.firstLunchStart) {
          this.shift.signed.firstLunchEnd = undefined;
          this.shift.signed.secondLunchStart = undefined;
          this.shift.signed.secondLunchEnd = undefined;
        }

        if (!this.shift.signed.secondLunchStart) {
          this.shift.signed.secondLunchEnd = undefined;
        }

        if (!this.setScheduledManually && this.newShift) {
          this.shift.scheduled.start = this.shift.signed.start;
          this.shift.scheduled.end = this.shift.signed.end;
        }
      },
      deep: true,
    },
    shiftId(newId, prevId) {
      this.shift.id = newId;
    },
    associateNumber(newNumber, prevNumber) {
      this.associate.number = newNumber;
    },
    isFirstLunchWaived(newValue) {
      if (newValue) {
        this.shift.signed.firstLunchStart = undefined;
        this.shift.signed.firstLunchEnd = undefined;
      }
    },
    isSecondLunchWaived(newValue) {
      if (newValue) {
        this.shift.signed.secondLunchStart = undefined;
        this.shift.signed.secondLunchEnd = undefined;
      }
    },
  },
  computed: {
    signatureAssociate() {
      return {
        name: Util.combineAssociateName(
          this.shift.signature.first_name,
          this.shift.signature.last_name
        ),
        email: this.shift.signature.email,
        phone: this.shift.signature.phone,
      };
    },
    showAssociateSelector() {
      return !this.shift.id && !this.associate.number;
    },
    minWorkDurationMinutes() {
      const appState = this.$store.state.applicationState;
      return appState.timeConstants && appState.timeConstants.min_work_duration
        ? appState.timeConstants.min_work_duration
        : DEFAULT_WORK_DURATION_IN_MINUTES;
    },
    newShift() {
      return !this.shift.id;
    },
    dateTimeFormat() {
      return this.$store.state.applicationState.dateTimeFormat;
    },
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    timeFormat() {
      return this.$store.state.applicationState.timeFormat;
    },

    formattedScheduledDate() {
      return this.formatDateRange(
        this.shift.scheduled.start,
        this.shift.scheduled.end
      );
    },
    formattedScheduledTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(
          this.shift.scheduled.start,
          this.shift.scheduled.end
        )
      );
    },
    formattedScheduledDuration() {
      if (!this.shift.scheduled.start || !this.shift.scheduled.end) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.scheduled.start,
        this.shift.scheduled.end
      );
    },

    formattedActualDate() {
      return this.formatDateRange(
        this.shift.actual.start,
        this.shift.actual.end
      );
    },
    formattedActualTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(this.shift.actual.start, this.shift.actual.end)
      );
    },
    formattedActualDuration() {
      if (!this.shift.actual.start || !this.shift.actual.end) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.actual.start,
        this.shift.actual.end
      );
    },
    formattedActualFirstLunchTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(
          this.shift.actual.firstLunchStart,
          this.shift.actual.firstLunchEnd
        )
      );
    },
    formattedActualFirstLunchDuration() {
      if (
        !this.shift.actual.firstLunchStart ||
        !this.shift.actual.firstLunchEnd
      ) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.actual.firstLunchStart,
        this.shift.actual.firstLunchEnd
      );
    },
    formattedActualSecondLunchTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(
          this.shift.actual.secondLunchStart,
          this.shift.actual.secondLunchEnd
        )
      );
    },
    formattedActualSecondLunchDuration() {
      if (
        !this.shift.actual.secondLunchStart ||
        !this.shift.actual.secondLunchEnd
      ) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.actual.secondLunchStart,
        this.shift.actual.secondLunchEnd
      );
    },

    formattedSignedDate() {
      return this.formatDateRange(
        this.shift.signed.start,
        this.shift.signed.end
      );
    },
    formattedSignedTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(this.shift.signed.start, this.shift.signed.end)
      );
    },
    formattedSignedDuration() {
      if (!this.shift.signed.start || !this.shift.signed.end) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.signed.start,
        this.shift.signed.end
      );
    },
    formattedSignedFirstLunchTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(
          this.shift.signed.firstLunchStart,
          this.shift.signed.firstLunchEnd
        )
      );
    },
    formattedSignedFirstLunchDuration() {
      if (
        !this.shift.signed.firstLunchStart ||
        !this.shift.signed.firstLunchEnd
      ) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.signed.firstLunchStart,
        this.shift.signed.firstLunchEnd
      );
    },
    formattedSignedSecondLunchTime() {
      return this.convertFormattedTimeRangeIntoString(
        this.formatTimeRange(
          this.shift.signed.secondLunchStart,
          this.shift.signed.secondLunchEnd
        )
      );
    },
    formattedSignedSecondLunchDuration() {
      if (
        !this.shift.signed.secondLunchStart ||
        !this.shift.signed.secondLunchEnd
      ) {
        return null;
      }

      return Util.getFormattedDuration(
        this.shift.signed.secondLunchStart,
        this.shift.signed.secondLunchEnd
      );
    },

    isScheduledEndDisabled() {
      return !this.shift.scheduled.end;
    },

    isSignedEndDisabled() {
      return !this.shift.signed.start;
    },

    isLunchTimeDisabled() {
      return (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        Util.countDuration(
          this.shift.signed.start,
          this.shift.signed.end,
          "minute"
        ) <
          DEFAULT_WORK_DURATION_IN_MINUTES / 2 ||
        this.isFirstLunchWaived
      );
    },
    isLunchEndDisabled() {
      return (
        this.isLunchTimeDisabled ||
        !this.shift.signed.firstLunchStart ||
        this.isFirstLunchWaived
      );
    },

    isSecondLunchTimeDisabled() {
      return (
        (this.isLunchTimeDisabled && !this.shift.signed.firstLunchStart) ||
        !this.shift.signed.firstLunchEnd ||
        this.isSecondLunchWaived
      );
    },
    isSecondLunchEndDisabled() {
      return (
        this.isSecondLunchTimeDisabled ||
        !this.shift.signed.secondLunchStart ||
        this.isSecondLunchWaived
      );
    },

    doesTheSignedShiftIncludeFirstLunch() {
      if (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        !this.shift.signed.firstLunchStart ||
        !this.shift.signed.firstLunchEnd
      ) {
        return false;
      }

      return (
        this.shift.signed.start.isBefore(
          moment(this.shift.signed.firstLunchStart),
          "minute"
        ) &&
        this.shift.signed.start.isBefore(
          moment(this.shift.signed.firstLunchEnd),
          "minute"
        ) &&
        this.shift.signed.end.isAfter(
          moment(this.shift.signed.firstLunchStart),
          "minute"
        ) &&
        this.shift.signed.end.isAfter(
          moment(this.shift.signed.firstLunchEnd),
          "minute"
        )
      );
    },

    doesTheSignedShiftIncludeSecondLunch() {
      if (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        !this.shift.signed.secondLunchStart ||
        !this.shift.signed.secondLunchEnd
      ) {
        return false;
      }

      return (
        this.shift.signed.start.isBefore(
          moment(this.shift.signed.secondLunchStart),
          "minute"
        ) &&
        this.shift.signed.start.isBefore(
          moment(this.shift.signed.secondLunchEnd),
          "minute"
        ) &&
        this.shift.signed.end.isAfter(
          moment(this.shift.signed.secondLunchStart),
          "minute"
        ) &&
        this.shift.signed.end.isAfter(
          moment(this.shift.signed.secondLunchEnd),
          "minute"
        )
      );
    },

    isSignedTimeValid() {
      // it can't cause any unexpected behaviour so let's take it as valid
      if (!this.shift.signed.start || !this.shift.signed.end) {
        return true;
      }

      return this.shift.signed.start.isBefore(this.shift.signed.end, "minute");
    },

    inconsistentFirstLunchData() {
      return (
        (!this.shift.signed.firstLunchStart &&
          this.shift.signed.firstLunchEnd) ||
        (this.shift.signed.firstLunchStart && !this.shift.signed.firstLunchEnd)
      );
    },
    inconsistentSecondLunchData() {
      return (
        (!this.shift.signed.secondLunchStart &&
          this.shift.signed.secondLunchEnd) ||
        (this.shift.signed.secondLunchStart &&
          !this.shift.signed.secondLunchEnd)
      );
    },
    unfilledFirstLunchData() {
      return (
        !this.shift.signed.firstLunchStart && !this.shift.signed.firstLunchEnd
      );
    },
    unfilledSecondLunchData() {
      return (
        !this.shift.signed.secondLunchStart && !this.shift.signed.secondLunchEnd
      );
    },
    partiallyUnfilledFirstLunchData() {
      return (
        !this.shift.signed.firstLunchStart || !this.shift.signed.firstLunchEnd
      );
    },
    partiallyUnfilledSecondLunchData() {
      return (
        !this.shift.signed.secondLunchStart || !this.shift.signed.secondLunchEnd
      );
    },

    disabledSaveButton() {
      return (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        this.inconsistentFirstLunchData ||
        this.inconsistentSecondLunchData ||
        this.loadingData ||
        !this.associate.number
      );
    },

    isSignedTimeFullyOrPartiallyUnfilled() {
      return !this.shift.signed.start || !this.shift.signed.end;
    },
    isSignedTimeUnfilled() {
      return !this.shift.signed.start && !this.shift.signed.end;
    },

    isFillEstimatedLunchesAvailable() {
      if (this.isSignedTimeFullyOrPartiallyUnfilled) {
        return false;
      }

      return (
        this.countDuration(
          this.shift.signed.start,
          this.shift.signed.end,
          "minutes"
        ) >= this.minWorkDurationMinutes
      );
    },

    disabledFillEstimatedLunchesButton() {
      return (
        this.isSignedTimeFullyOrPartiallyUnfilled ||
        !this.unfilledFirstLunchData ||
        !this.unfilledSecondLunchData ||
        !this.isFillEstimatedLunchesAvailable ||
        this.isLunchTimeDisabled
      );
    },

    disabledWorkTimeButton() {
      return !this.shift.id;
    },

    formattedActualWorkHours() {
      return this.formatWorkHours(
        this.shift.actual.start,
        this.shift.actual.end,
        this.shift.actual.firstLunchStart,
        this.shift.actual.firstLunchEnd,
        this.shift.actual.secondLunchStart,
        this.shift.actual.secondLunchEnd
      );
    },
    formattedActualPayHours() {
      return this.formatPayHours(
        this.shift.scheduled.start,
        this.shift.scheduled.end,
        this.shift.actual.start,
        this.shift.actual.end,
        this.shift.actual.firstLunchStart,
        this.shift.actual.firstLunchEnd,
        this.shift.actual.secondLunchStart,
        this.shift.actual.secondLunchEnd
      );
    },

    formattedWorkHours() {
      return this.formatWorkHours(
        this.shift.signed.start,
        this.shift.signed.end,
        this.shift.signed.firstLunchStart,
        this.shift.signed.firstLunchEnd,
        this.shift.signed.secondLunchStart,
        this.shift.signed.secondLunchEnd
      );
    },
    formattedPayHours() {
      return this.formatPayHours(
        this.shift.scheduled.start,
        this.shift.scheduled.end,
        this.shift.signed.start,
        this.shift.signed.end,
        this.shift.signed.firstLunchStart,
        this.shift.signed.firstLunchEnd,
        this.shift.signed.secondLunchStart,
        this.shift.signed.secondLunchEnd
      );
    },

    timeZone() {
      return this.$store.state.applicationState.timeZone;
    },

    showAssociateShiftHeader() {
      return (
        this.associate && this.associate.firstName && this.associate.lastName
      );
    },

    noAttachments() {
      return this.shiftAttachments.length == 0;
    },

    showScheduled() {
      return this.activeTab == "scheduled";
    },
    showActual() {
      return this.activeTab == "actual";
    },
    showSigned() {
      return this.activeTab == "signed";
    },

    paymentUnavailable() {
      return !this.associatedPaymentRequestId;
    },

    minWorkDuration() {
      return this.$store.state.applicationState.timeConstants.min_work_duration;
    },
    minWorkWithWaiverDuration() {
      return this.$store.state.applicationState.timeConstants
        .min_work_with_waiver_duration;
    },
    longWorkDuration() {
      return this.$store.state.applicationState.timeConstants
        .long_work_duration;
    },
    longWorkWithWaiverDuration() {
      return this.$store.state.applicationState.timeConstants
        .long_work_with_waiver_duration;
    },

    isFirstLunchWaiveAvailable() {
      if (!this.shift.signed.start || !this.shift.signed.end) {
        return false;
      }

      const signedShiftDuration = Util.countDuration(
        this.shift.signed.start,
        this.shift.signed.end,
        "minute"
      );
      return (
        signedShiftDuration >= this.minWorkDuration &&
        signedShiftDuration < this.minWorkWithWaiverDuration
      );
    },
    isSecondLunchWaiveAvailable() {
      if (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        !this.shift.signed.firstLunchStart ||
        !this.shift.signed.firstLunchEnd
      ) {
        return false;
      }
      const signedShiftDuration = Util.countDuration(
        this.shift.signed.start,
        this.shift.signed.end,
        "minute"
      );

      const signedLunchDuration = Util.countDuration(
        this.shift.signed.firstLunchStart,
        this.shift.signed.firstLunchEnd,
        "minute"
      );

      return (
        signedShiftDuration - signedLunchDuration >= this.longWorkDuration &&
        signedShiftDuration - signedLunchDuration <
          this.longWorkWithWaiverDuration
      );
    },

    firstLunchWaiveInfo() {
      return this.getLunchWaiveInfoMessage(true);
    },
    secondLunchWaiveInfo() {
      return this.getLunchWaiveInfoMessage(false);
    },
  },
  methods: {
    getLunchWaiveInfoMessage(isFirstLunch) {
      const messages = [];

      if (!this.associate.isLunchWaiver) {
        messages.push("This associate does not have a lunch waiver.");

        return messages;
      }

      if (isFirstLunch && !this.isFirstLunchWaiveAvailable) {
        messages.push(
          "An associate can waive the first lunch period if work time is 6 hours or less."
        );
      }

      if (!isFirstLunch && !this.isSecondLunchWaiveAvailable) {
        messages.push(
          "An associate can waive the second lunch period if they work more than 10 but less than 12 hours, and only if they have already taken the first lunch."
        );
      }

      if (!isFirstLunch) {
        messages.push(
          "If the work hours exceed 12 hours, the second lunch period cannot be waived."
        );
      }

      return messages;
    },
    showAutofillWarning(description) {
      notification.warning({
        message: "Autofill Warning",
        description,
      });
    },
    fillEstimatedLunches() {
      if (this.isSignedTimeUnfilled) {
        notification["warning"]({
          message: "Signed Time Not Filled",
          description: "Please fill signed time and try again",
        });
      }

      this.apiGetEstimatedLunches(
        this.associate.number,
        this.convertDateTimeToUTC(this.shift.signed.start),
        this.convertDateTimeToUTC(this.shift.signed.end)
      ).then((response) => {
        const data = response.data;

        if (data.error_code && data.error_code !== "0") {
          this.showAutofillWarning(data.msg);
          return;
        }

        if (data.estimated_lunch_start && data.estimated_lunch_end) {
          this.shift.signed.firstLunchStart = moment
            .utc(data.estimated_lunch_start)
            .tz(this.timeZone);
          this.shift.signed.firstLunchEnd = moment
            .utc(data.estimated_lunch_end)
            .tz(this.timeZone);
        }

        if (
          data.estimated_second_lunch_start &&
          data.estimated_second_lunch_end
        ) {
          this.shift.signed.secondLunchStart = moment
            .utc(data.estimated_second_lunch_start)
            .tz(this.timeZone);
          this.shift.signed.secondLunchEnd = moment
            .utc(data.estimated_second_lunch_end)
            .tz(this.timeZone);
        }
      });
    },
    fillWorkTime() {
      this.shift.signed.start = this.shift.scheduled.start;
      this.shift.signed.end = this.shift.scheduled.end;
    },
    convertFormattedTimeRangeIntoString(formattedTimeRange) {
      if (
        !formattedTimeRange ||
        formattedTimeRange.start == "-" ||
        formattedTimeRange.end == "-"
      ) {
        return "-";
      }

      return formattedTimeRange.start + " - " + formattedTimeRange.end;
    },

    disabledScheduledStartDate(date) {
      if (!this.shift.scheduled.end) {
        return false;
      }

      return this.shift.scheduled.end.isBefore(moment(date), "day");
    },

    disabledScheduledEndDate(date) {
      if (!this.shift.scheduled.start) {
        return false;
      }

      return this.shift.scheduled.start.isAfter(moment(date), "day");
    },

    disabledSignedStartDate(date) {
      if (!this.shift.signed.end) {
        return false;
      }

      return this.shift.signed.end.isBefore(moment(date), "day");
    },

    disabledSignedEndDate(date) {
      if (!this.shift.signed.start) {
        return false;
      }

      return this.shift.signed.start.isAfter(moment(date), "day");
    },

    disabledFirstLunchStartDate(date) {
      if (!this.shift.signed.start || !this.shift.signed.end) {
        return true;
      }

      return (
        this.shift.signed.start.isAfter(moment(date), "day") ||
        this.shift.signed.end.isBefore(moment(date), "day")
      );
    },

    disabledFirstLunchEndDate(date) {
      if (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        !this.shift.signed.firstLunchStart
      ) {
        return true;
      }

      return (
        this.shift.signed.end.isBefore(moment(date), "day") ||
        this.shift.signed.firstLunchStart.isAfter(moment(date), "day")
      );
    },

    disabledSecondLunchStartDate(date) {
      if (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        !this.shift.signed.firstLunchStart ||
        !this.shift.signed.firstLunchEnd
      ) {
        return true;
      }

      return (
        this.shift.signed.start.isAfter(moment(date), "day") ||
        this.shift.signed.end.isBefore(moment(date), "day")
      );
    },

    disabledSecondLunchEndDate(date) {
      if (
        !this.shift.signed.start ||
        !this.shift.signed.end ||
        !this.shift.signed.firstLunchStart ||
        !this.shift.signed.firstLunchEnd ||
        !this.shift.signed.secondLunchStart
      ) {
        return true;
      }

      return (
        this.shift.signed.start.isAfter(moment(date), "day") ||
        this.shift.signed.end.isBefore(moment(date), "day")
      );
    },

    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    calculateDisabledDateTimeRange(start, end, pickerType) {
      let disabledHours = () => [],
        disabledMinutes = () => [];

      if (start != null && end == null && start.diff(moment(), "days") == 0) {
        end = moment();
      } else if (
        start == null &&
        end != null &&
        end.diff(moment(), "days") == 0
      ) {
        start = moment();
      }

      if (start != null && end != null && start.isSame(end, "day")) {
        const hourRange = this.range(0, 24);
        const minutesRange = this.range(0, 60);

        switch (pickerType) {
          case "start":
            disabledHours = () => this.range(0, 24).slice(end.hours() + 1, 24);

            if (end.hours() == start.hours()) {
              disabledMinutes = () => minutesRange.slice(end.minutes() + 1, 60);
            }
            break;
          case "end":
            disabledHours = () => hourRange.slice(0, start.hours());

            if (start.hours() == end.hours()) {
              disabledMinutes = () => minutesRange.slice(0, start.minutes());
            }
            break;
        }
      }

      return { disabledHours, disabledMinutes };
    },

    disabledScheduledStartTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.scheduled.start,
        this.shift.scheduled.end,
        "start"
      );
    },
    disabledScheduledEndTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.scheduled.start,
        this.shift.scheduled.end,
        "end"
      );
    },
    disabledSignedStartTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.signed.start,
        this.shift.signed.end,
        "start"
      );
    },
    disabledSignedEndTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.signed.start,
        this.shift.signed.end,
        "end"
      );
    },
    disabledSignedLunchStartTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.signed.firstLunchStart,
        this.shift.signed.firstLunchEnd,
        "start"
      );
    },
    disabledSignedLunchEndTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.signed.firstLunchStart,
        this.shift.signed.firstLunchEnd,
        "end"
      );
    },
    disabledSignedSecondLunchStartTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.signed.secondLunchStart,
        this.shift.signed.secondLunchEnd,
        "start"
      );
    },
    disabledSignedSecondLunchEndTime() {
      return this.calculateDisabledDateTimeRange(
        this.shift.signed.secondLunchStart,
        this.shift.signed.secondLunchEnd,
        "end"
      );
    },

    verifyLunchesHitTheShift() {
      if (!this.doesTheSignedShiftIncludeFirstLunch) {
        this.shift.signed.firstLunchStart = undefined;
        this.shift.signed.firstLunchEnd = undefined;
      }

      if (!this.doesTheSignedShiftIncludeSecondLunch) {
        this.shift.signed.secondLunchStart = undefined;
        this.shift.signed.secondLunchEnd = undefined;
      }
    },

    handleChangeSignedStart() {
      this.shift.signed.start = this.resetSeconds(this.shift.signed.start);
      this.verifyLunchesHitTheShift();

      if (!this.isSignedTimeValid) {
        this.shift.signed.end = this.resetSeconds(
          this.shift.signed.start
            .clone()
            .add(DEFAULT_WORK_DURATION_IN_MINUTES, "minutes")
        );
      }
    },
    handleChangeSignedEnd() {
      this.shift.signed.end = this.resetSeconds(this.shift.signed.end);
      this.handleChangeSignedStart();
    },

    handleFirstLunchTimeInput() {
      if (
        moment(this.shift.signed.firstLunchStart).isSameOrBefore(
          this.shift.signed.start,
          "minute"
        )
      ) {
        this.shift.signed.firstLunchStart = this.shift.signed.start
          .clone()
          .add(1, "minutes");
      }

      if (
        this.shift.signed.firstLunchEnd &&
        moment(this.shift.signed.firstLunchEnd).isSameOrBefore(
          this.shift.signed.firstLunchStart,
          "minute"
        )
      ) {
        this.shift.signed.firstLunchEnd = this.shift.signed.firstLunchStart
          .clone()
          .add(1, "minutes");
      }
    },

    handleSecondLunchTimeInput() {
      if (
        moment(this.shift.signed.secondLunchStart).isSameOrBefore(
          this.shift.signed.firstLunchEnd,
          "minute"
        )
      ) {
        this.shift.signed.secondLunchStart = this.shift.signed.firstLunchEnd
          .clone()
          .add(1, "minutes");
      }

      if (
        this.shift.signed.secondLunchEnd &&
        moment(this.shift.signed.secondLunchEnd).isSameOrBefore(
          this.shift.signed.secondLunchStart,
          "minute"
        )
      ) {
        this.shift.signed.secondLunchEnd = this.shift.signed.secondLunchStart
          .clone()
          .add(1, "minutes");
      }
    },

    convertDateTimeToUTC(dateTime) {
      if (!dateTime) {
        return null;
      }
      return moment(dateTime).utc().toISOString().substring(0, 16);
    },
    convertDateTimeToISO8601(dateTime) {
      if (!dateTime) {
        return null;
      }

      return moment(dateTime).utc().toISOString();
    },

    async loadData() {
      this.loadingData = true;
      if (this.shift.id) {
        await this.apiGetAssociateShift(this.shift.id).then((response) => {
          const data = response.data;

          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Associate Shift Info Load Error",
              description: "Please try to open associate shift again",
            });
            return;
          }

          this.associate.number = data.employee_id;

          this.shift.scheduled.start = data.scheduled_start.length
            ? moment.utc(data.scheduled_start).tz(this.timeZone)
            : undefined;
          this.shift.scheduled.end = data.scheduled_end.length
            ? moment.utc(data.scheduled_end).tz(this.timeZone)
            : undefined;

          this.shift.actual.start = data.work_start.length
            ? moment.utc(data.work_start).tz(this.timeZone)
            : undefined;
          this.shift.actual.end = data.work_end.length
            ? moment.utc(data.work_end).tz(this.timeZone)
            : undefined;
          this.shift.actual.firstLunchStart = data.lunch_start
            ? moment.utc(data.lunch_start).tz(this.timeZone)
            : undefined;
          this.shift.actual.firstLunchEnd = data.lunch_end
            ? moment.utc(data.lunch_end).tz(this.timeZone)
            : undefined;
          this.shift.actual.secondLunchStart = data.second_lunch_start
            ? moment.utc(data.second_lunch_start).tz(this.timeZone)
            : undefined;
          this.shift.actual.secondLunchEnd = data.second_lunch_end
            ? moment.utc(data.second_lunch_end).tz(this.timeZone)
            : undefined;

          this.shift.signed.start = data.signed_start.length
            ? moment.utc(data.signed_start).tz(this.timeZone)
            : undefined;
          this.shift.signed.end = data.signed_end.length
            ? moment.utc(data.signed_end).tz(this.timeZone)
            : undefined;
          this.shift.signed.firstLunchStart = data.signed_lunch_start
            ? moment.utc(data.signed_lunch_start).tz(this.timeZone)
            : undefined;
          this.shift.signed.firstLunchEnd = data.signed_lunch_end
            ? moment.utc(data.signed_lunch_end).tz(this.timeZone)
            : undefined;
          this.shift.signed.secondLunchStart = data.signed_second_lunch_start
            ? moment.utc(data.signed_second_lunch_start).tz(this.timeZone)
            : undefined;
          this.shift.signed.secondLunchEnd = data.signed_second_lunch_end
            ? moment.utc(data.signed_second_lunch_end).tz(this.timeZone)
            : undefined;

          this.shiftAttachments = data.files ?? [];

          this.version = data.version;
          this.associatedPaymentRequestId = data.payment_request;

          this.isFirstLunchWaived =
            this.isFirstLunchWaiveAvailable && !!data.lunch_waived;
          this.isSecondLunchWaived =
            this.isSecondLunchWaiveAvailable && !!data.lunch_waived;

          this.shift.signature = data.signature;
        });
      }

      if (this.associate.number) {
        await this.apiGetAssociate(this.associate.number).then((response) => {
          const data = response.data;

          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Associate Data Load Error",
              description: "Please try to open associate shift again",
            });
            return;
          }

          this.associate.firstName = data.first_name;
          this.associate.lastName = data.last_name;
          this.associate.isLunchWaiver = data.is_lunch_waiver;
        });
      }

      this.loadingData = false;
    },
    initiateShiftSave() {
      if (this.multipleShiftView) {
        this.$emit("save-shift-on-multiple-view", {
          work_start: this.convertDateTimeToISO8601(this.shift.signed.start),
          work_end: this.convertDateTimeToISO8601(this.shift.signed.end),
          lunch_start: this.convertDateTimeToISO8601(
            this.shift.signed.firstLunchStart
          ),
          lunch_end: this.convertDateTimeToISO8601(
            this.shift.signed.firstLunchEnd
          ),
          second_lunch_start: this.convertDateTimeToISO8601(
            this.shift.signed.secondLunchStart
          ),
          second_lunch_end: this.convertDateTimeToISO8601(
            this.shift.signed.secondLunchEnd
          ),
        });
        return;
      }

      this.loadingData = true;
      this.apiCheckAuths(
        this.associate.number,
        this.getShiftDataForCheckAuths(),
        this.isFirstLunchWaived || this.isSecondLunchWaived
      )
        .then((response) => {
          this.authorizations = response.body.authorizations;
          this.openConfirmationModal();
        })
        .catch((error) => {
          notification["warning"]({
            message: "Error",
            description:
              "An error occurred while checking authorizations. Please contact the administrator",
          });
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    saveShiftAnyway(autoSign, signatureId) {
      this.autoSign = autoSign;
      this.signatureId = signatureId;
      this.saveWithAuths = true;
      this.closeConfirmationModal();
      this.saveShift();
      this.authorizations = [];
    },
    getShiftDataForCheckAuths() {
      return {
        id: this.shift.id,
        work_start: this.shift.signed.start,
        work_end: this.shift.signed.end,
        lunch_start: this.shift.signed.firstLunchStart,
        lunch_end: this.shift.signed.firstLunchEnd,
        second_lunch_start: this.shift.signed.secondLunchStart,
        second_lunch_end: this.shift.signed.secondLunchEnd,
      };
    },
    saveShift() {
      this.loadingData = true;
      if (this.saveWithAuths) {
        if (!this.shift.id) {
          this.closeConfirmationModal();
          this.saveNewShift();
        } else {
          this.adjustShift();
        }
        this.saveWithAuths = false;
        this.loadingData = false;
      } else {
        this.initiateShiftSave();
      }
    },
    async saveNewShift() {
      let overlapCheckSuccess = true;

      await this.apiCheckShiftConflict(
        this.associate.number,
        this.convertDateTimeToISO8601(this.shift.signed.start),
        this.convertDateTimeToISO8601(this.shift.signed.end)
      ).then((response) => {
        const data = response.data;

        if (data.error_code && data.error_code !== "0") {
          notification["warning"]({
            message: "Shift Overlap Check Error",
            description:
              "An error occurred while checking shift overlap. Please try again later.",
          });

          overlapCheckSuccess = false;
          return;
        }

        if (this.isOverlapExists(data.result)) {
          overlapCheckSuccess = false;
        }
      });

      if (!overlapCheckSuccess) {
        return;
      }

      this.apiAddAssociateShift(
        this.associate.number,
        this.convertDateTimeToISO8601(this.shift.signed.start),
        this.convertDateTimeToISO8601(this.shift.signed.end),
        this.convertDateTimeToISO8601(this.shift.signed.firstLunchStart),
        this.convertDateTimeToISO8601(this.shift.signed.firstLunchEnd),
        this.convertDateTimeToISO8601(this.shift.signed.secondLunchStart),
        this.convertDateTimeToISO8601(this.shift.signed.secondLunchEnd),
        this.autoSign,
        this.isFirstLunchWaived || this.isSecondLunchWaived,
        this.signatureId
      )
        .then((response) => {
          switch (Number(response.data.error_code)) {
            case 0:
              notification["success"]({
                message: "Associate Shift Saved",
              });
              if (
                this.attachmentsFileIds &&
                this.attachmentsFileIds.length > 0
              ) {
                this.apiAddAttachmentsToShift(
                  response.body.shift_id,
                  this.attachmentsFileIds
                ).then((response) => {
                  const errorCode = response.data.errorCode;
                });
              }

              this.$emit("on-add-or-edit");
              break;
            case 1:
              notification["warning"]({
                message: "Associate Shift Add Error",
                description:
                  response.data.msg ??
                  "An error occurred while adding a shift. Please contact the administrator",
              });
              break;
            case 2:
              this.closeConfirmationModal();
              this.$emit("shift-exists", response.body.overlapping_shift_ids);
              break;
          }
        })
        .catch((error) => {
          notification["warning"]({
            message: "Error",
            description:
              "An error occurred while adding a shift. Please contact the administrator",
          });
        });
    },
    convertShiftsArrayToDatesString(array, format) {
      return array
        .map((item) => {
          return moment
            .tz(item.work_start ?? item.scheduled_start, "UTC")
            .format(format);
        })
        .join(", ");
    },
    isOverlapExists(resultArray) {
      if (resultArray && resultArray.length > 0) {
        const shifts = resultArray[0].shifts;
        if (!shifts || shifts.length == 0) {
          return false;
        }

        const format = this.$store.state.applicationState.dateTimeFormat;

        notification["warning"]({
          message: "Shift Overlap Warning",
          description:
            "The shift overlaps with shifts starting at: " +
            this.convertShiftsArrayToDatesString(resultArray[0].shifts, format),
        });

        return true;
      }

      return false;
    },
    async adjustShift() {
      const changed_associate_shift = {
        id: this.shift.id,
        signed_start: moment(this.shift.signed.start)
          .tz(this.timeZone)
          .format(this.timeFormat),
        signed_end: moment(this.shift.signed.end)
          .tz(this.timeZone)
          .format(this.timeFormat),
        signed_first_lunch_start: this.shift.signed.firstLunchStart
          ? moment(this.shift.signed.firstLunchStart)
              .tz(this.timeZone)
              .format(this.timeFormat)
          : null,
        signed_first_lunch_end: this.shift.signed.firstLunchEnd
          ? moment(this.shift.signed.firstLunchEnd)
              .tz(this.timeZone)
              .format(this.timeFormat)
          : null,
        signed_second_lunch_start: this.shift.signed.secondLunchStart
          ? moment(this.shift.signed.secondLunchStart)
              .tz(this.timeZone)
              .format(this.timeFormat)
          : null,
        signed_second_lunch_end: this.shift.signed.secondLunchEnd
          ? moment(this.shift.signed.secondLunchEnd)
              .tz(this.timeZone)
              .format(this.timeFormat)
          : null,
        signed_duration: this.formattedSignedDuration,
        signed_work_hours: this.formattedWorkHours,
        pay_hours: this.formattedPayHours,
        signed_first_lunch_duration: this.formattedSignedFirstLunchDuration,
        signed_seconds_lunch_duration: this.formattedSignedSecondLunchDuration,
        authorizations: this.authorizations,
      };

      let overlapCheckSuccess = true;

      await this.apiCheckShiftConflict(
        this.associate.number,
        this.convertDateTimeToISO8601(this.shift.signed.start),
        this.convertDateTimeToISO8601(this.shift.signed.end),
        this.shift.id
      ).then((response) => {
        const data = response.data;

        if (data.error_code && data.error_code !== "0") {
          notification["warning"]({
            message: "Shift Overlap Check Error",
            description:
              "An error occurred while checking shift overlap. Please try again later.",
          });

          overlapCheckSuccess = false;
          return;
        }

        if (this.isOverlapExists(data.result)) {
          overlapCheckSuccess = false;
        }
      });

      if (!overlapCheckSuccess) {
        return;
      }

      this.apiSetAssociateShift(
        this.shift.id,
        this.convertDateTimeToISO8601(this.shift.signed.start),
        this.convertDateTimeToISO8601(this.shift.signed.end),
        this.convertDateTimeToISO8601(this.shift.signed.firstLunchStart),
        this.convertDateTimeToISO8601(this.shift.signed.firstLunchEnd),
        this.convertDateTimeToISO8601(this.shift.signed.secondLunchStart),
        this.convertDateTimeToISO8601(this.shift.signed.secondLunchEnd),
        this.autoSign,
        this.setScheduledAndActualAsSigned,
        this.isFirstLunchWaived || this.isSecondLunchWaived,
        this.signatureId
      )
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Associate Shift Save Error",
              description:
                data.msg ??
                "An error occurred while editing a shift. Please contact the administrator",
            });
          } else {
            notification["success"]({
              message: "Associate Shift Saved",
            });

            this.$emit("on-add-or-edit", changed_associate_shift);
          }
        })
        .catch((error) => {
          notification["warning"]({
            message: "Error",
            description:
              "An error occurred while editing a shift. Please contact the administrator",
          });
        });
    },
    deleteShift() {
      this.$emit("on-shift-deletion", this.shift.id, this.version);
    },

    setDefaultFirstLunchStartValue(open) {
      if (
        !open ||
        !this.shift.signed.start ||
        this.shift.signed.firstLunchStart
      ) {
        return;
      }

      this.shift.signed.firstLunchStart = this.shift.signed.start
        .clone()
        .add(60, "minutes");
    },
    setDefaultFirstLunchEndValue(open) {
      if (
        !open ||
        !this.shift.signed.firstLunchStart ||
        this.shift.signed.firstLunchEnd
      ) {
        return;
      }

      this.shift.signed.firstLunchEnd = this.shift.signed.firstLunchStart
        .clone()
        .add(30, "minutes");
    },
    setDefaultSecondLunchStartValue(open) {
      if (
        !open ||
        !this.shift.signed.firstLunchEnd ||
        this.shift.signed.secondLunchStart
      ) {
        return;
      }

      this.shift.signed.secondLunchStart = this.shift.signed.firstLunchEnd
        .clone()
        .add(60, "minutes");
    },
    setDefaultSecondLunchEndValue(open) {
      if (
        !open ||
        !this.shift.signed.secondLunchStart ||
        this.shift.signed.secondLunchEnd
      ) {
        return;
      }

      this.shift.signed.secondLunchEnd = this.shift.signed.secondLunchStart
        .clone()
        .add(30, "minutes");
    },

    cleanData() {
      Object.assign(this.$data, {
        shift: {
          id: undefined,
          scheduled: {
            start: undefined,
            end: undefined,
          },
          signed: {
            start: undefined,
            end: undefined,

            firstLunchStart: undefined,
            firstLunchEnd: undefined,

            secondLunchStart: undefined,
            secondLunchEnd: undefined,
          },
        },

        signedTimePickerOpen: false,
        setScheduledManually: false,
        loadingData: false,
      });
    },

    clearSignedTime() {
      this.shift.signed.start = undefined;
      this.shift.signed.end = undefined;

      this.clearFirstLunch();
      this.clearSecondLunch();
    },
    clearFirstLunch() {
      this.shift.signed.firstLunchStart = undefined;
      this.shift.signed.secondLunchStart = undefined;
    },
    clearSecondLunch() {
      this.shift.signed.secondLunchStart = undefined;
      this.shift.signed.secondLunchEnd = undefined;
    },
    closeConfirmationModal() {
      this.visibleConfirmationModal = false;
    },
    openConfirmationModal() {
      this.shiftConfirmationComponentKey++;
      this.visibleConfirmationModal = true;
    },

    openAddAttachments() {
      this.shiftAttachmentsKey += 1;
      this.addAttachmentsVisible = true;
    },
    closeAddAttachments() {
      this.addAttachmentsVisible = false;
    },

    openAttachments() {
      this.attachmentsVisible = true;
    },
    closeAttachments() {
      this.attachmentsVisible = false;
    },

    openDocumentOnNewPage(file) {
      window.open(file.url, "_blank");
    },

    onTabChange(key, type) {
      this[type] = key;
    },

    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.loadingAssociates = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map((user) => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id,
          }));
          this.loadingAssociates = false;
        });
      }, 1000);
    },
    handleFocusAssociates() {
      this.fetchAssociates();
    },
    handleSearchAssociatesChange(value) {
      this.associate.number = value;
      this.loadData();
      this.$refs.associateSelect.blur();
    },

    addNewAssociate() {
      this.$emit("on-associate-create");
    },

    saveAttachmentsFileIds(ids) {
      this.attachmentsFileIds = ids;
      this.closeAddAttachments();
    },

    documentUploaded() {
      this.closeAddAttachments();
      this.loadData();
    },

    openPaymentRequest() {
      this.openedPaymentId = this.associatedPaymentRequestId;
    },
  },

  mounted() {
    if (this.shiftId) {
      this.shift.id = this.shiftId;
    }

    if (this.associateNumber) {
      this.associate.number = this.associateNumber;
    }

    this.loadData();
  },
};
</script>

<style scoped>
.associate-shift-controls,
.associate-shift-header {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content-view-block .ant-calendar-picker {
  min-width: 100px !important;
}

.content-view-block b {
  width: fit-content;
}

.content-view {
  display: flex;
  padding: 10px 30px;
  text-align: left;
  overflow: auto;
  height: 650px;
  gap: 24px;
}

.content-view > * {
  width: 50%;
  min-width: auto;
}

.content-view p {
  gap: 10px;
}

.content-view p b {
  flex: 0 0 80px;
  text-align: right;
}

.content-view p:last-child {
  margin-bottom: 1em;
}

.scheduled-picker-group {
  margin-top: 20px;
}

.time-picker {
  width: 150px;
}

.attachments-buttons,
.associate-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.associate-buttons {
  padding-bottom: 10px;
}

.associate-select {
  width: 200px;
}

.content-view .content-view-block:nth-child(n + 2) {
  margin-left: 0;
}

@media screen and (max-width: 968px) {
  .content-view {
    flex-direction: column;
    height: 70dvh;
  }

  .content-view > * {
    width: 100%;
    max-width: 100%;
  }
}
</style>
