import apiClient from "@/services/api";
import moment from "moment-timezone";

export default {
  /**
   * Fetches payrolls for the current project and commits the data to the store.
   * It handles pagination with default values for `page` and `size`.
   * If the request fails or the server returns an error, an exception is thrown.
   * The loading state is managed using the `setLoadingPayrolls` mutation.
   *
   * @param {Object} context - Vuex context object containing commit and rootState.
   * @param {Function} context.commit - Vuex commit function to call mutations.
   * @param {Object} context.rootState - Vuex root state, used to get the current project ID.
   * @param {Object} payload - Payload containing pagination parameters.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchPayrolls({ commit, rootState }, { page = 1, size = 10 }) {
    try {
      const { data } = await apiClient.post("/api/payroll/get_all", {
        project_id: rootState.applicationState.currentProject.id,
        page,
        size,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setPayrolls", data);
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches a single payroll by ID and commits the data to the store.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing the payroll ID.
   * @param {String} payload.payroll_id - The ID of the payroll to fetch.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchPayroll({ commit, rootState }, { payroll_id }) {
    try {
      commit("setLoadingActivePayrolls", true);

      const { data } = await apiClient.post(`/api/payroll/${payroll_id}/get`);

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setActivePayroll", data.payroll);
    } catch (error) {
      throw error.message;
    } finally {
      commit("setLoadingActivePayrolls", false);
    }
  },

  /**
   * Fetches all pay periods for the current project and commits the data to the store.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchPayPeriods({ commit, rootState }) {
    try {
      const { data } = await apiClient.post(`/api/payroll/pay_period/get_all`, {
        project_id: rootState.applicationState.currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setPayPeriods", data.pay_periods);
    } catch (error) {
      throw error.message;
    } finally {
    }
  },

  /**
   * Creates a new payroll for the current project and commits the data to the store.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll details.
   * @param {String} payload.pay_period_start - The start date of the pay period.
   * @param {String} payload.pay_period_end - The end date of the pay period.
   * @param {String} payload.preparing_end - The preparation end date.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async createPayroll(
    { commit, rootState },
    { pay_period_start, pay_period_end, preparing_end }
  ) {
    try {
      const { data } = await apiClient.post(`/api/payroll/create`, {
        project_id: rootState.applicationState.currentProject.id,
        pay_period_start,
        pay_period_end,
        preparing_end,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setActivePayroll", data.payroll);
    } catch (error) {
      throw error.message;
    } finally {
    }
  },

  /**
   * Fetches the active payroll for the current project and commits it to the store.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchActivePayroll({ commit, rootState }) {
    const { currentProject, dateFormat } = rootState.applicationState;

    try {
      commit("setLoadingActivePayrolls", true);

      const { data } = await apiClient.post(`/api/payroll/active/get`, {
        project_id: currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setActivePayroll", data.payroll);
    } catch (error) {
      throw error.message;
    } finally {
      commit("setLoadingActivePayrolls", false);
    }
  },

  /**
   * Fetches metrics for a specific payroll by ID and commits the data to the store.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing the payroll ID.
   * @param {String} payload.payroll_id - The ID of the payroll for which metrics are to be fetched.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchMetrics({ commit }, { payroll_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/metrics/get`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setMetrics", data.metrics);
    } catch (error) {
      throw error.message;
    } finally {
    }
  },

  /**
   * Fetches adjustments for a specific payroll by ID and commits the data to the store.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing the payroll ID.
   * @param {String} payload.payroll_id - The ID of the payroll for which adjustments are to be fetched.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchAdjustments({ commit }, { payroll_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/adjustment/get_all`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAdjustments", data.adjustments);
    } catch (error) {
      throw error.message;
    } finally {
    }
  },

  /**
   * Fetches all adjustment shifts for a specific adjustment and payroll ID and commits the data to the store.
   * It handles pagination with default values for `page` and `size`.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing adjustment and payroll IDs and pagination parameters.
   * @param {String} payload.adjustment_id - The ID of the adjustment for which shifts are to be fetched.
   * @param {String} payload.payroll_id - The ID of the payroll related to the adjustment.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchAdjustmentShifts(
    { commit },
    { adjustment_id, payroll_id, page = 1, size = 10 }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/adjustment/${adjustment_id}/shift/get_all`,
        { page, size }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAdjustmentsShifts", {
        adjustment_id,
        shifts: data,
      });
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches estimated shifts for a specific payroll ID and commits the data to the store.
   * It handles pagination with default values for `page`, `size`, and applies a default filter.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll ID, pagination parameters, and filter.
   * @param {String} payload.payroll_id - The ID of the payroll for which estimated shifts are to be fetched.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   * @param {Object} [payload.filter={ type: "ready_to_pay" }] - Filter for fetching shifts (default filter: { type: "ready_to_pay" }).
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchEstimatedShifts(
    { commit },
    { payroll_id, page = 1, size = 10, filter = { type: "ready_to_pay" } }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/shift/estimated/get_all`,
        {
          page,
          size,
          filter,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setShifts", {
        type: filter.type,
        shifts: data,
      });
    } catch (error) {
      throw error.message;
    }
  },

  async fetchShifts(
    { commit },
    { payroll_id, page = 1, size = 10, filter = { type: "ready_to_pay" } }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/shift/get_all`,
        {
          page,
          size,
          filter,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setShifts", {
        type: filter.type,
        shifts: data,
      });
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches all payment requests for the active payroll and commits the data to the store.
   * It handles pagination with default values for `page` and `size`.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing pagination parameters and filter.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   * @param {Object} [payload.filter={}] - Optional filter for fetching payment requests.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchPaymentRequests(
    { state, commit },
    { page = 1, size = 10, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${state.activePayroll.id}/payment_request/get_all`,
        {
          page,
          size,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setPaymentRequests", data);
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches all signed associates for a specific payroll ID and commits the data to the store.
   * It handles pagination with default values for `page` and `size`.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll ID and pagination parameters.
   * @param {String} payload.payroll_id - The ID of the payroll for which signed associates are to be fetched.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   * @param {Object} [payload.filter={}] - Optional filter for fetching signed associates.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchSignedAssociates(
    { commit },
    { payroll_id, page = 1, size = 10, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/signed/get_all`,
        {
          page,
          size,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setSignedAssociates", {
        items: data.associates,
        total_count: data.associates.length,
      });
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Fetches all unsigned associates for a specific payroll ID and commits the data to the store.
   * It handles pagination with default values for `page` and `size`.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll ID and pagination parameters.
   * @param {String} payload.payroll_id - The ID of the payroll for which unsigned associates are to be fetched.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   * @param {Object} [payload.filter={}] - Optional filter for fetching unsigned associates.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchUnsignedAssociates(
    { commit },
    { payroll_id, page = 1, size = 10, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/not_signed/get_all`,
        {
          page,
          size,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setUnsignedAssociates", {
        items: data.associates,
        total_count: data.associates.length,
      });
    } catch (error) {
      throw error.message;
    } finally {
    }
  },

  /**
   * Fetches associates that require validation for a specific payroll ID and commits the data to the store.
   * It handles pagination with default values for `page` and `size`, and applies a filter for statuses.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll ID and pagination parameters.
   * @param {String} payload.payroll_id - The ID of the payroll for which validation associates are to be fetched.
   * @param {Number} [payload.page=1] - The page number for pagination (default: 1).
   * @param {Number} [payload.size=10] - The number of items per page (default: 10).
   * @param {Object} [payload.filter={}] - Optional filter for fetching validation associates.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async fetchValidationAssociates(
    { commit },
    { payroll_id, page = 1, size = 10, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/validation/get_all`,
        {
          page,
          size,
          filter: {
            statuses: ["initial", "started"],
          },
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setValidationAssociates", data);
    } catch (error) {
      throw error.message;
    } finally {
    }
  },

  /**
   * Starts the validation process for a specific associate in a payroll.
   * It updates the validation status of the associate in the state and fetches metrics after starting validation.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll and associate IDs.
   * @param {String} payload.payroll_id - The ID of the payroll in which validation starts.
   * @param {String} payload.associate_id - The ID of the associate being validated.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async startValidate(
    { commit, state, dispatch },
    { payroll_id, associate_id }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/shift/validate/start`,
        {
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      const updatedItems = state.validationAssociates.items.map((item) =>
        item.id === associate_id
          ? { ...item, payroll_validation_status: "started" }
          : item
      );

      commit("setValidationAssociates", {
        ...state.validationAssociates,
        items: updatedItems,
      });

      dispatch("fetchMetrics", { payroll_id });
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Completes the validation process for a specific associate in a payroll.
   * It updates the state by removing the associate from validation associates and adding them to unsigned associates.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll and associate IDs.
   * @param {String} payload.payroll_id - The ID of the payroll in which validation is completed.
   * @param {String} payload.associate_id - The ID of the associate being validated.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async completeValidate(
    { commit, state, dispatch },
    { payroll_id, associate_id }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/shift/validate/complete`,
        {
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      const associate = state.validationAssociates.items.find(
        (item) => item.id === associate_id
      );

      if (associate) {
        commit("setUnsignedAssociates", {
          items: [
            ...state.unsignedAssociates.items,
            { ...associate, payroll_validation_status: "validated" },
          ],
          total_count: state.unsignedAssociates.total_count + 1,
        });
      }

      await dispatch("fetchMetrics", { payroll_id });
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Signs a payroll for a specific associate by an admin.
   * It updates the state by moving the associate from unsigned associates to signed associates.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll and associate IDs.
   * @param {String} payload.payroll_id - The ID of the payroll being signed.
   * @param {String} payload.signature_id - The ID of the signature used for signing.
   * @param {String | Array} payload.associate_id - The ID or IDs of the associate(s) being signed.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async signPayrollByAdmin(
    { commit, state, dispatch },
    { payroll_id, signature_id, associate_id }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/by_admin/sign`,
        {
          signature_id,
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      const associates =
        state.unsignedAssociates.items.filter((item) =>
          associate_id.includes(item.id)
        ) ?? [];

      if (associates.length) {
        const newAssociates = associates.map((item) => ({
          ...item,
          payroll_validation_status: "admin_signed",
        }));

        commit("setSignedAssociates", {
          items: [...state.signedAssociates.items, ...newAssociates],
          total_count: state.signedAssociates.total_count + 1,
        });
      }

      await dispatch("fetchMetrics", { payroll_id });
    } catch (error) {
      throw error.message;
    }
  },

  /**
   * Completes a payroll process by signing it with the provided signature.
   * It updates the state to set the active payroll to null once the payroll is completed.
   * If the request fails or the server returns an error, an exception is thrown.
   *
   * @param {Object} payload - Payload containing payroll and signature IDs.
   * @param {String} payload.payroll_id - The ID of the payroll being completed.
   * @param {String} payload.signature_id - The ID of the signature used for completing the payroll.
   *
   * @throws {Error} Throws an error if the request fails or the server returns a non-zero error code.
   */
  async completePayroll({ commit }, { payroll_id, signature_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/complete`,
        {
          signature_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setActivePayroll", null);
    } catch (error) {
      throw error.message;
    }
  },

  async fetchAssociatesShifts(
    { commit },
    { payroll_id, associate_id, page = 1, size = 10, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/shift/estimated/get_all`,
        {
          page,
          size,
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAssociateShifts", data);
    } catch (error) {
      throw error.message;
    }
  },

  async fetchAssociatesPayments(
    { commit },
    { payroll_id, associate_id, page = 1, size = 10, filter = {} }
  ) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/associate/payment_request/get_all`,
        {
          page,
          size,
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAssociatePayments", data);
    } catch (error) {
      throw error.message;
    }
  },

  async downloadPayrollReportTimeAttendance({ commit }, { payroll_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/report/time_attendance/send`,
        {
          email: "shinonhorror@gmail.com",
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAssociateShifts", data);
    } catch (error) {
      throw error.message;
    }
  },

  async downloadPayrollReportShiftTimeCards({ commit }, { payroll_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/payroll/${payroll_id}/report/shift_cards/get`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAssociateShifts", data);
    } catch (error) {
      throw error.message;
    }
  },
};
