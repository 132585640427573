import { PAYMENT_STATUS } from "./constants";

export default {
  getPaymentStatus() {
    return (status, method) => {
      if (method == "Manual") {
        return {
          title: "Awaiting payment",
          color: "#ffb546",
        };
      }

      return PAYMENT_STATUS[status] ?? PAYMENT_STATUS.CREATED;
    };
  },
};
