export default {
  setPayments(state, payload) {
    state.payments = payload;
  },
  setPayment(state, payload) {
    state.payment = payload;
  },
  setStatuses(state, payload) {
    state.statuses = payload;
  },
  setSystems(state, payload) {
    state.systems = payload;
  },
};
