import { INITIAL_STATE } from "./constants";

export default {
  setPayrolls(state, payload) {
    state.payrolls = payload;
  },
  setActivePayroll(state, payload) {
    state.activePayroll = payload;
  },
  setLoadingActivePayrolls(state, payload) {
    state.loadingActivePayroll = payload;
  },
  setMetrics(state, payload) {
    state.metrics = payload;
  },
  setAdjustments(state, payload) {
    state.adjustments = payload;
  },
  setAdjustmentsShifts(state, { adjustment_id, shifts }) {
    state.adjustmentsShifts[adjustment_id] = shifts;
  },
  setPayPeriods(state, payload) {
    state.payPeriods = payload;
  },
  setShifts(state, { type, shifts }) {
    state.shifts[type] = shifts;
  },
  setPaymentRequests(state, payload) {
    state.paymentRequests = payload;
  },
  setValidationAssociates(state, payload) {
    state.validationAssociates = payload;
  },
  setSignedAssociates(state, payload) {
    state.signedAssociates = payload;
  },
  setUnsignedAssociates(state, payload) {
    state.unsignedAssociates = payload;
  },
  setAssociateShifts(state, payload) {
    state.associateShifts = payload;
  },
  setAssociatePayments(state, payload) {
    state.associatePayments = payload;
  },
  resetState(state) {
    state = INITIAL_STATE;
  },
};
