export default {
  setShiftAuthorizations(state, payload) {
    state.shiftAuthorizations = payload;
  },
  setShifts(state, payload) {
    state.shifts = payload;
  },
  setShift(state, payload) {
    state.shift = payload;
  },
};
