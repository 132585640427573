import { render, staticRenderFns } from "./create-write-up.vue?vue&type=template&id=4a605405&scoped=true&"
import script from "./create-write-up.vue?vue&type=script&lang=js&"
export * from "./create-write-up.vue?vue&type=script&lang=js&"
import style0 from "./create-write-up.vue?vue&type=style&index=0&id=4a605405&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a605405",
  null
  
)

export default component.exports