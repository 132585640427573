<template>
  <div>
    <div class="content-view">
      <div
        class="content-view-block"
        style="max-width: unset !important; width: 100%"
      >
        <p>
          <b>Date:</b>
          {{ shift.signed_date }}
        </p>
        <p>
          <b>Time:</b>
          {{ shift.signed_start + " - " + shift.signed_end }}
        </p>
        <p>
          <b>Duration:</b>
          {{ shift.signed_duration }}
        </p>
        <p>
          <b>Lunch:</b>
          {{
            shift.signed_first_lunch_start +
            " - " +
            shift.signed_first_lunch_end
          }}
        </p>
        <p>
          <b>Lunch Duration:</b>
          {{ shift.signed_first_lunch_duration }}
        </p>
        <p>
          <b>Second Lunch:</b>
          {{
            shift.signed_second_lunch_start +
            " - " +
            shift.signed_second_lunch_end
          }}
        </p>
        <p>
          <b>Second Lunch Duration:</b>
          {{ shift.signed_second_lunch_duration }}
        </p>
        <p>
          <b>Work Hours:</b>
          {{ shift.work_hours }}
        </p>
        <p>
          <b>Pay Hours:</b>
          {{ shift.pay_hours }}
        </p>
        <signature
          checkbox-label="I agree that all data is true and correct"
          :associate="signatureAssociate"
          :available-signature="adminAssociateSignature"
          @checked="setSignatureCheckboxValue"
          @save-signature="setSignatureId"
        />
      </div>
    </div>

    <div class="button-group">
      <a-button
        type="primary"
        :disabled="!checked"
        :loading="loadingButton"
        @click="signShift"
      >
        Sign
      </a-button>
    </div>
  </div>
</template>

<script>
import { Button, Card } from "ant-design-vue";
import Signature from "@/components/signature.vue";
import Util from "@/util";

export default {
  components: {
    "a-card": Card,
    "a-button": Button,
    signature: Signature,
  },
  data() {
    return {
      checked: false,
      loadingButton: false,

      signatureId: undefined,
    };
  },
  props: {
    shift: {
      type: Object,
      required: true,
    },
  },
  computed: {
    signatureAssociate() {
      return {
        name: Util.combineAssociateName(
          this.$store.state.applicationState.associate.first_name,
          this.$store.state.applicationState.associate.last_name
        ),
        email: this.$store.state.applicationState.associate.email,
        phone: this.$store.state.applicationState.associate.phone,
      };
    },
    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    },
  },
  methods: {
    signShift() {
      this.loadingButton = true;
      this.$emit("sign", this.signatureId);
    },
    setSignatureCheckboxValue(val) {
      this.checked = val;
    },
    setSignatureId(signatureId) {
      this.signatureId = signatureId;
    },
  },
};
</script>

<style scoped>
.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
