import apiClient from "@/services/api";

export default {
  async fetchShiftAuthorizations({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/get_current_shift_auths", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setShiftAuthorizations", data.authorizations);
    } catch (error) {
      throw error.message;
    }
  },

  async deleteShift({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/tc_delete_employee_shift", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updateShiftAuthorizations(
    { commit },
    { ids, status, comment, params }
  ) {
    try {
      const { data } = await apiClient.post(
        "/api/tc_set_auth_status_for_multiple_auths",
        {
          ids,
          status,
          comment,
          ...params,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getShifts({ rootState }, { page = 1, size = 10, filter = {} }) {
    try {
      const { data } = await apiClient.post(`api/shift/get_all`, {
        project_id: rootState.applicationState.currentProject.id,
        page,
        size,
        filter,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the shifts. Please try again later"
        );
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getPayCode({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the pay code. Please try again later"
        );
      }

      commit("setShift", data.shift);
    } catch (error) {
      throw error.message;
    }
  },

  async createPayCode({ commit, rootState }, { dates, associates, pay_code }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/create", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code_shift_form: { dates, associates, pay_code },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while creating the pay code. Please try again later"
        );
      }

      commit("setShifts", data);
    } catch (error) {
      throw error.message;
    }
  },

  async attachPayCode({ commit, rootState }, { pay_code, shift_ids }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/attach", {
        project_id: rootState.applicationState.currentProject.id,
        author_id: rootState.applicationState.associate.id,
        pay_code,
        shift_ids,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while attaching the pay code. Please try again later"
        );
      }

      commit("setShifts", data);
    } catch (error) {
      throw error.message;
    }
  },

  async updatePayCode({ commit, rootState }, { pay_code, shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/update", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code,
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while attaching the pay code. Please try again later"
        );
      }

      commit("setShifts", data);
    } catch (error) {
      throw error.message;
    }
  },
};
