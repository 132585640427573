import Util from "@/util";
import moment from "moment-timezone";
import store from "@/store";
import {formatDate} from "ant-design-vue/lib/date-picker/utils";

export default {
  methods: {
    getDateInterval(start, end, format) {
      switch (true) {
        case (!start || start.length == 0) && (end && end.length > 0):
          return 'N/A - ' + Util.convertDateTimeToCurrentTimeZoneWithFormat(end, format);
        case (start && start.length > 0) && (!end || end.length == 0):
          return Util.convertDateTimeToCurrentTimeZoneWithFormat(start, format) + ' - N/A';
        case (start && start.length > 0) && (end && end.length > 0):
          const formattedStart = Util.convertDateTimeToCurrentTimeZoneWithFormat(start, format);
          const formattedEnd = Util.convertDateTimeToCurrentTimeZoneWithFormat(end, format);

          return formattedStart == formattedEnd ? formattedStart : formattedStart + ' - ' + formattedEnd;
      }

      return 'N/A';
    },
    getPrefixes(period) {
      let scheduledFieldPrefix, actualFieldPrefix, signedFieldPrefix;

      switch (period) {
        case 'shift_duration':
          scheduledFieldPrefix = 'scheduled';
          actualFieldPrefix = 'work';
          signedFieldPrefix = 'signed';
          break;
        case 'first_lunch':
          actualFieldPrefix = 'lunch';
          signedFieldPrefix = 'signed_lunch';
          break;
        case 'second_lunch':
          actualFieldPrefix = 'second_lunch';
          signedFieldPrefix = 'signed_second_lunch';
          break;
        default:
          console.error('Invalid period of interval');
          return {};
      }

      return {scheduledFieldPrefix, actualFieldPrefix, signedFieldPrefix};
    },
    getFormattedIntervals(shift, period, format) {
      const {scheduledFieldPrefix, actualFieldPrefix, signedFieldPrefix} = this.getPrefixes(period);
      let result = {};

      if (!period.includes('lunch')) {
        const scheduledStart = shift[scheduledFieldPrefix + '_start'];
        const scheduledEnd = shift[scheduledFieldPrefix + '_end'];
        result.scheduledInterval = this.getDateInterval(scheduledStart, scheduledEnd, format);
      }

      const actualStart = shift[actualFieldPrefix + '_start'];
      const actualEnd = shift[actualFieldPrefix + '_end'];
      result.actualInterval = this.getDateInterval(actualStart, actualEnd, format);

      const signedStart = shift[signedFieldPrefix + '_start'];
      const signedEnd = shift[signedFieldPrefix + '_end'];
      result.signedInterval = this.getDateInterval(signedStart, signedEnd, format);

      return result;
    },
    getDateInfo(shift) {
      const intervals = this.getFormattedIntervals(shift, 'shift_duration', 'L');

      let cellInfo = '';

      if (
        intervals.scheduledInterval === intervals.actualInterval
        && intervals.actualInterval === intervals.signedInterval
      ) {
        cellInfo = intervals.scheduledInterval;
      } else {
        if (intervals.scheduledInterval !== 'N/A')
          cellInfo += 'Scheduled: ' + intervals.scheduledInterval + '\n';
        if (intervals.actualInterval !== 'N/A')
          cellInfo += 'Actual: ' + intervals.actualInterval + '\n'
        if (intervals.signedInterval !== 'N/A')
          cellInfo += 'Signed: ' + intervals.signedInterval;
      }

      if (cellInfo.length === 0) {
        cellInfo = '-';
      }

      return cellInfo;
    },
    countAllDurations(shift, period) {
      const {scheduledFieldPrefix, actualFieldPrefix, signedFieldPrefix} = this.getPrefixes(period);
      let scheduledDuration, actualDuration, signedDuration;

      if (shift[scheduledFieldPrefix + '_start'] && shift[scheduledFieldPrefix + '_end']) {
        scheduledDuration = Util.getFormattedDuration(
          moment(shift[scheduledFieldPrefix + '_start']),
          moment(shift[scheduledFieldPrefix + '_end'])
        );
      }

      if (shift[actualFieldPrefix + '_start'] && shift[actualFieldPrefix + '_end']) {
        actualDuration = Util.getFormattedDuration(
          moment(shift[actualFieldPrefix + '_start']),
          moment(shift[actualFieldPrefix + '_end'])
        );
      }

      if (shift[signedFieldPrefix + '_start'] && shift[signedFieldPrefix + '_end']) {
        signedDuration = Util.getFormattedDuration(
          moment(shift[signedFieldPrefix + '_start']),
          moment(shift[signedFieldPrefix + '_end'])
        );
      }

      return {scheduledDuration, actualDuration, signedDuration};
    },
    getTimeDurationInfo(shift, period) {
      const intervals = this.getFormattedIntervals(shift, period, 'LT');
      const countedDurations = this.countAllDurations(shift, period);

      let startFlagName, endFlagName, result = '';

      switch (period) {
        case 'shift_duration':
          startFlagName = 'start_in_wa';
          endFlagName = 'end_in_wa';
          break;
        case 'first_lunch':
          startFlagName = 'lunch1_start_in_wa';
          endFlagName = 'lunch1_end_in_wa';
          break;
        case 'second_lunch':
          startFlagName = 'lunch2_start_in_wa';
          endFlagName = 'lunch2_end_in_wa';
          break;
        default:
          console.error('Invalid period of interval');
          return '-';
      }

      if (intervals.actualInterval !== 'N/A') {
        result += 'Actual: ';
        result += intervals.actualInterval;
        if (countedDurations.actualDuration) result += ' (' + countedDurations.actualDuration + ')';
        result += '\n';
      }

      if (intervals.signedInterval !== 'N/A') {
        result += 'Signed: ' + intervals.signedInterval;
        if (countedDurations.signedDuration) result += ' (' + countedDurations.signedDuration + ')';
      }


      if (!period.includes('lunch') && intervals.scheduledInterval != 'N/A') {
        let scheduledResult = 'Scheduled: ' + intervals.scheduledInterval;
        if (countedDurations.scheduledDuration) {
          scheduledResult += ' (' + countedDurations.scheduledDuration + ')';
        }
        result = scheduledResult + '\n' + result;
      }

      if (result.length == 0) {
        result = '-';
      }

      return result;
    },

    minutesToHoursAndMinutes(totalMinutes) {
      if (!totalMinutes || totalMinutes == 0) {
        return '-';
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60)

      let formattedResult = '';

      if (hours !== 0) formattedResult += hours + 'h';
      if (hours && minutes) formattedResult += ' ';
      if (minutes !== 0 || totalMinutes === 0) formattedResult += minutes + 'm';

      return formattedResult;
    },
    hoursToHoursAndMinutes(num) {
      const wholeHours = Math.floor(num);
      const minutes = Math.round((num - wholeHours) * 60);

      let result = '';
      if (wholeHours) {
        result += `${wholeHours}h `;
      }
      if (minutes) {
        result += `${minutes}m`;
      }

      return result.trim();
    },
    getOvertimesInfo(shift) {
      let result = '';

      if (shift.overt1_signed) {
        result += `Signed OT1: ${this.hoursToHoursAndMinutes(shift.overt1_signed)}\n`;
      }

      if (shift.overt2_signed) {
        result += `Signed OT2: ${this.hoursToHoursAndMinutes(shift.overt2_signed)}`;
      }

      return result.length ? result : '-';
    },
    formatDateRange(start, end) {
      if (start == null || end == null || start.length == 0 || end.length == 0) {
        return '-';
      }

      const format = store.state.applicationState.dateFormat;

      const startDate = Util.convertDateTimeToCurrentTimeZoneWithFormat(start, format);
      const endDate = Util.convertDateTimeToCurrentTimeZoneWithFormat(end, format);

      if (startDate == endDate) {
        return startDate;
      }

      return `${startDate} - ${endDate}`;
    },
    formatTimeRange(start, end, is_lunch = false) {
      const format = store.state.applicationState.timeFormat;

      const startTime = Util.convertDateTimeToCurrentTimeZoneWithFormat(start, format);
      const endTime = Util.convertDateTimeToCurrentTimeZoneWithFormat(end, format);
      const waivedMessage = is_lunch ? 'Waived' : '-';

      return {
        start: startTime ?? waivedMessage,
        end: endTime ?? waivedMessage
      };
    },

    formatShiftDurations(shift) {
      const scheduledDateDuration = this.formatDateRange(shift.scheduled_start, shift.scheduled_end);
      const actualDateDuration = this.formatDateRange(shift.start ?? shift.work_start, shift.end ?? shift.work_end);
      const signedDateDuration = this.formatDateRange(shift.signed_start, shift.signed_end);

      const scheduledTimeDuration = this.formatTimeRange(shift.scheduled_start, shift.scheduled_end);
      const actualTimeDuration = this.formatTimeRange(shift.start ?? shift.work_start, shift.end ?? shift.work_end);
      const signedTimeDuration = this.formatTimeRange(shift.signed_start, shift.signed_end);

      const actualFirstLunchDuration = this.formatTimeRange(
        shift.lunch_start ?? shift.lunch1_start,
        shift.lunch_end ?? shift.lunch1_end
      );
      const signedFirstLunchDuration = this.formatTimeRange(
        shift.signed_lunch_start ?? shift.signed_lunch1_start,
        shift.signed_lunch_end ?? shift.signed_lunch1_end
      );

      const actualSecondLunchDuration = this.formatTimeRange(
        shift.second_lunch_start ?? shift.lunch2_start,
        shift.second_lunch_end ?? shift.lunch2_end
      );
      const signedSecondLunchDuration = this.formatTimeRange(
        shift.signed_second_lunch_start ?? shift.signed_lunch2_start,
        shift.signed_second_lunch_end ?? shift.signed_lunch2_end
      );

      return {
        scheduled: {
          date: scheduledDateDuration,
          start: scheduledTimeDuration.start,
          end: scheduledTimeDuration.end
        },
        actual: {
          date: actualDateDuration,
          start: actualTimeDuration.start,
          end: actualTimeDuration.end,
          first_lunch_start: actualFirstLunchDuration.start,
          first_lunch_end: actualFirstLunchDuration.end,
          second_lunch_start: actualSecondLunchDuration.start,
          second_lunch_end: actualSecondLunchDuration.end
        },
        signed: {
          date: signedDateDuration,
          start: signedTimeDuration.start,
          end: signedTimeDuration.end,
          first_lunch_start: signedFirstLunchDuration.start,
          first_lunch_end: signedFirstLunchDuration.end,
          second_lunch_start: signedSecondLunchDuration.start,
          second_lunch_end: signedSecondLunchDuration.end
        }
      }
    },

    formatShiftAuthDurations(shift) {
      const scheduledDateDuration = this.formatDateRange(shift.scheduled_start, shift.scheduled_end);
      const actualDateDuration = this.formatDateRange(shift.work_start, shift.work_end);
      const signedDateDuration = this.formatDateRange(shift.signed_start, shift.signed_end);

      const scheduledTimeDuration = this.formatTimeRange(shift.scheduled_start, shift.scheduled_end);
      const actualTimeDuration = this.formatTimeRange(shift.work_start, shift.work_end);
      const signedTimeDuration = this.formatTimeRange(shift.signed_start, shift.signed_end);

      const signedFirstLunchDuration = this.formatTimeRange(
        shift.lunch_start,
        shift.lunch_end,
        true
      );

      const signedSecondLunchDuration = this.formatTimeRange(
        shift.second_lunch_start,
        shift.second_lunch_end,
        true
      );

      return {
        scheduled: {
          date: scheduledDateDuration,
          start: scheduledTimeDuration.start,
          end: scheduledTimeDuration.end
        },
        actual: {
          date: actualDateDuration,
          start: actualTimeDuration.start,
          end: actualTimeDuration.end,
          first_lunch_start: signedFirstLunchDuration.start,
          first_lunch_end: signedFirstLunchDuration.end,
          second_lunch_start: signedSecondLunchDuration.start,
          second_lunch_end: signedSecondLunchDuration.end
        },
        signed: {
          date: signedDateDuration,
          start: signedTimeDuration.start,
          end: signedTimeDuration.end,
          first_lunch_start: signedFirstLunchDuration.start,
          first_lunch_end: signedFirstLunchDuration.end,
          second_lunch_start: signedSecondLunchDuration.start,
          second_lunch_end: signedSecondLunchDuration.end
        }
      }
    },

    formatWorkHours(
      signedStart,
      signedEnd,
      signedFirstLunchStart,
      signedFirstLunchEnd,
      signedSecondLunchStart,
      signedSecondLunchEnd
    ) {
      if (!signedStart || !signedEnd) {
        return '-';
      }

      let totalDurationMinutes = signedEnd.diff(signedStart, 'minutes');

      if (signedFirstLunchStart && signedFirstLunchEnd) {
        let firstLunchDuration = signedFirstLunchEnd.diff(signedFirstLunchStart, 'minutes');
        totalDurationMinutes -= firstLunchDuration;
      }

      if (signedSecondLunchStart && signedSecondLunchEnd) {
        let secondLunchDuration = signedSecondLunchEnd.diff(signedSecondLunchStart, 'minutes');
        totalDurationMinutes -= secondLunchDuration;
      }

      return this.minutesToHoursAndMinutes(totalDurationMinutes);
    },
    formatPayHours(
      scheduledStart,
      scheduledEnd,
      signedStart,
      signedEnd,
      signedFirstLunchStart,
      signedFirstLunchEnd,
      signedSecondLunchStart,
      signedSecondLunchEnd
    ) {
      if (!signedStart || !signedEnd) {
        return '-';
      }

      const timeConstants = store.state.applicationState.timeConstants;

      let workDuration = signedEnd.diff(signedStart, 'minutes');

      if (scheduledStart && signedStart) {
        const lateStartDuration = signedStart.diff(scheduledStart, 'minutes');
        const considerGracePeriod = lateStartDuration <= timeConstants.after_shift_start_grace_period &&
          signedStart.isSameOrAfter(scheduledStart);
        if (considerGracePeriod) {
          workDuration += lateStartDuration;
        }
      }

      if (scheduledEnd && signedEnd) {
        const earlyEndDuration = scheduledEnd.diff(signedEnd, 'minutes');
        const considerGracePeriod = earlyEndDuration <= timeConstants.before_shift_end_grace_period &&
          scheduledEnd.isSameOrAfter(signedEnd);
        if (considerGracePeriod) {
          workDuration += earlyEndDuration;
        }
      }

      if (signedFirstLunchStart && signedFirstLunchEnd) {
        const firstLunchDuration = signedFirstLunchEnd.diff(signedFirstLunchStart, 'minutes');
        workDuration -= firstLunchDuration;
      }
      if (signedSecondLunchStart && signedSecondLunchEnd) {
        const secondLunchDuration = signedSecondLunchEnd.diff(signedSecondLunchStart, 'minutes');
        workDuration -= secondLunchDuration;
      }

      return this.minutesToHoursAndMinutes(workDuration);
    },

    countDuration(startMoment, endMoment, timeUnit) {
      return endMoment.diff(startMoment, timeUnit);
    },

    resetSeconds(date) {
      if (date) {
        return date.clone().seconds(0).milliseconds(0);
      }
      return date;
    },
  },
}
