import Cookies from "js-cookie";

export default {
  setToken(state, token) {
    state.token = token;

    if (token === null) {
      localStorage.removeItem("applicationState");
      Cookies.remove("csrftoken");
      Cookies.remove("sessionid");
    }
  },
  setUser(state, user) {
    state.user = user;
  },
  setAssociate(state, associate) {
    state.associate = associate;
  },
  setCurrentProject(state, currentProject) {
    state.currentProject = currentProject;
  },
  setAllProjects(state, allProjects) {
    state.allProjects = allProjects;
  },
  setDateFromFilter(state, dateFrom) {
    state.dateFromFilter = dateFrom;
  },
  setDateToFilter(state, dateTo) {
    state.dateToFilter = dateTo;
  },
  setTimeCardsSelectedEmployee(state, selectedEmployee) {
    state.timeCardsSelectedEmployee = selectedEmployee;
  },
  setShiftAuthSelectedEmployee(state, selectedEmployee) {
    state.shiftAuthSelectedEmployee = selectedEmployee;
  },
  setTimeZone(state, timeZone) {
    state.timeZone = timeZone;
    localStorage.setItem("timeZone", timeZone);
  },
  setIsProd(state, isProd) {
    state.isProd = isProd;
  },
  setCurrentPaymentType(state, paymentType) {
    state.currentPaymentType = paymentType;
  },
  setCurrentPaymentTypeName(state, paymentTypeName) {
    state.currentPaymentTypeName = paymentTypeName;
  },
  setShiftAuthTypes(state, types) {
    state.shiftAuthTypes = types;
  },
  setExtendedShiftAuthTypes(state, types) {
    state.extendedShiftAuthTypes = types;
  },
  setTimeConstants(state, constants) {
    state.timeConstants = constants;
  },
  setProjectRoles(state, roles) {
    state.projectRoles = roles;
  },
  setScheduleTypes(state, scheduleTypes) {
    state.scheduleTypes = scheduleTypes;
  },
  setPaymentTypes(state, paymentTypes) {
    state.paymentTypes = paymentTypes;
  },
  setProjectLegalEntities(state, legalEntities) {
    state.projectLegalEntities = legalEntities;
  },
  setApplicationState(state, newState) {
    Object.assign(state, newState);
  },
};
