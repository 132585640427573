import apiClient from "@/services/api";

export default {
  async downloadTimeAttendance(
    { commit, rootState },
    { start_date, end_date, report_type = "timesheet" }
  ) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.post(
        `/api/project/report/time_attendance/get`,
        {
          project_id: currentProject.id,
          start_date,
          end_date,
          report_type,
          device_id: "9afbdd2824fc7073",
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async sendTimeAttendance(
    { commit, rootState },
    { email, start_date, end_date, report_type = "timesheet" }
  ) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.post(
        `/api/project/report/time_attendance/send_email`,
        {
          project_id: currentProject.id,
          email,
          start_date,
          end_date,
          report_type,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async downloadShiftCards(
    { commit, rootState },
    { start_date, end_date, report_type = "timesheet" }
  ) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.post(
        `/api/project/report/shift_cards/get`,
        {
          project_id: currentProject.id,
          email,
          start_date,
          end_date,
          report_type,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async sendShiftCards(
    { commit, rootState },
    { email, start_date, end_date, report_type = "timesheet" }
  ) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.post(
        `/api/project/report/shift_cards/send_email`,
        {
          project_id: currentProject.id,
          email,
          start_date,
          end_date,
          report_type,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async downloadPayrollSystem(
    { commit, rootState },
    { start_date, end_date, report_type = "timesheet" }
  ) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.post(
        `/api/project/report/payroll_system/get`,
        {
          project_id: currentProject.id,
          start_date,
          end_date,
          report_type,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async sendPayrollSystem(
    { commit, rootState },
    { email, start_date, end_date, report_type = "timesheet" }
  ) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.post(
        `/api/project/report/payroll_system/send_email`,
        {
          project_id: currentProject.id,
          email,
          start_date,
          end_date,
          report_type,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },
};
