<template>
  <div style="flex-grow: 1">
    <a-table
      size="small"
      :columns="columns"
      :data-source="projects"
      :loading="loading"
      :row-key="generateRowKey"
      :row-selection="{
        selectedRowKeys: currentAssociateNumberAndProjectIdArrayWrapped,
        onChange: onSelectChange,
        type: 'radio',
      }"
    >
      <span slot="role" slot-scope="text, record">
        <div class="flex flex-column gap-2">
          <a-tag v-if="record.is_owner === true" color="#eb8f92">OWNER</a-tag>
          <a-tag
            v-if="
              record.associate_role && record.associate_role.name.length > 0
            "
            color="#238e75"
          >
            {{ record.associate_role.name.toUpperCase() }}
          </a-tag>
        </div>
      </span>
    </a-table>
  </div>
</template>

<script>
import api from "@/api";
import { Table, Tag, notification } from "ant-design-vue";
import store from "@/store";

export default {
  components: {
    "a-table": Table,
    "a-tag": Tag,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          title: "Company ID",
          dataIndex: "id",
          key: "id",
          sorter: (a, b) => a.id - b.id,
          align: "center",
        },
        {
          title: "Company Name",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "Associate Number",
          dataIndex: "associate_number",
          key: "associate_number",
          align: "center",
        },
        {
          title: "Job Classification",
          key: "role",
          scopedSlots: { customRender: "role" },
          align: "center",
        },
      ],
    };
  },
  computed: {
    projects() {
      return this.$store.state.applicationState.allProjects.filter(
        (project) => project.is_active_associate === true
      );
    },
    currentAssociateNumberAndProjectIdArrayWrapped() {
      return [
        this.$store.state.applicationState.currentProject.id +
          "_" +
          this.$store.state.applicationState.associate.associate_number,
      ];
    },
    currentProject() {
      return this.$store.state.applicationState.currentProject;
    },
  },
  mixins: [api],
  methods: {
    async changeProject(newProjectId) {
      this.loading = true;
      await this.apiSwitchCurrentProject(newProjectId)
        .then(async ({ body }) => {
          if (!body.error_code || body.error_code === "0") {
            await store.dispatch("loadApplicationState");
          }
        })
        .finally(() => (this.loading = false));
    },
    async onSelectChange(newAssociateNumberAndProjectIdArrayWrapped) {
      const newProjectId =
        newAssociateNumberAndProjectIdArrayWrapped[0].split("_")[0];
      const oldProjectName = this.currentProject.name;

      await this.changeProject(newProjectId);

      if (this.currentProject.id == newProjectId) {
        notification["success"]({
          message: "Success",
          description:
            "Project successfully switched from " +
            oldProjectName +
            " to " +
            this.currentProject.name,
        });
      } else {
        notification["error"]({
          message: "Error",
          description:
            "An error occurred when switching the company. Please contact the administrator",
        });
      }
    },
    generateRowKey(record) {
      return `${record.id}_${record.associate_number}`;
    },
  },
};
</script>
