<template>
  <a-modal
    title="Reset Password"
    width="300px"
    v-model="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <transition name="bounce">
      <a-alert
        class="mb-2"
        v-if="resetErrorMsg"
        type="error"
        :message="resetErrorMsg"
      />
    </transition>
    <a-form v-if="!verificationCodeSent" :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          v-decorator="[
            'username',
            {
              rules: [
                { required: true, message: 'Please enter your username' },
                { type: 'email', message: usernameErrorMsg },
              ],
              validateTrigger: ['blur', 'change'],
            },
          ]"
          :disabled="loading"
          placeholder="Username"
          :allow-clear="true"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item class="no-margin">
        <a-input
          v-decorator="[
            'password',
            {
              rules: [
                { required: true, message: 'Please enter your password' },
                { min: 6, message: 'Password must be at least 6 characters' },
              ],
              validateTrigger: ['blur', 'change'],
            },
          ]"
          type="password"
          placeholder="New password"
          :disabled="loading"
          @change="handlePasswordChange"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item class="no-margin">
        <a-input
          v-decorator="[
            'passwordConfirm',
            {
              rules: [
                { required: true, message: 'Please repeat your password' },
                {
                  validator: validateToNextPassword,
                  message: 'Passwords do not match',
                },
              ],
              validateTrigger: ['blur', 'change'],
            },
          ]"
          type="password"
          placeholder="Repeat password"
          :disabled="loading || passwordInvalid"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <div class="flex flex-column gap-2 justify-content-center mt-4">
          <a-button
            type="primary"
            html-type="submit"
            :disabled="hasErrors(form.getFieldsError())"
            :loading="loading"
          >
            Send Verification Code
          </a-button>
        </div>
      </a-form-item>
    </a-form>
    <div v-else class="flex flex-column gap-4 justify-content-center">
      <div>
        A verification code has been sent to your email address {{ username }}.
        Please enter it below.
      </div>
      <a-input v-model="verificationCode" placeholder="Code" />
      <a-button
        type="primary"
        :disabled="!verificationCode || !verificationCode.length"
        :loading="loading"
        @click="resetPassword"
      >
        Reset Password
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import {
  Modal,
  Form,
  Button,
  Input,
  Icon,
  Alert,
  notification,
} from "ant-design-vue";
import api from "@/api";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

export default {
  components: {
    "a-modal": Modal,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-button": Button,
    "a-input": Input,
    "a-icon": Icon,
    "a-alert": Alert,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [api],
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, { name: "reset-password" }),
      usernameErrorMsg: "The username should be email",
      loading: false,
      passwordInvalid: true,
      resetErrorMsg: undefined,
      sentCode: undefined,

      username: undefined,
      newPassword: undefined,
      verificationCodeSent: false,
      verificationCode: undefined,
    };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    validateToNextPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue("password")) {
        console.log(value, this.form.getFieldValue("password"));
        callback("Passwords do not match!");
        return false;
      }

      callback();
    },
    handlePasswordChange() {
      this.$nextTick(() => {
        this.form.validateFields(["password"], (errors) => {
          if (!errors) {
            this.passwordInvalid = false;
          } else {
            this.passwordInvalid = true;
            this.form.resetFields(["passwordConfirm"]);
          }
        });
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.username = values.username;
          this.newPassword = values.password;

          this.loading = true;
          this.apiResetPassword(values.username)
            .then(({ body }) => {
              if (body.error_code && body.error_code !== "0") {
                this.resetErrorMsg = body.msg;
                setTimeout(() => {
                  this.resetErrorMsg = undefined;
                }, 3000);
                this.form.resetFields();
                return;
              }

              this.verificationCodeSent = true;
            })
            .finally(() => (this.loading = false));
        }
      });
    },
    resetPassword() {
      this.loading = true;
      this.apiConfirmResetPassword(
        this.username,
        this.newPassword,
        this.verificationCode
      )
        .then(({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            this.resetErrorMsg = body.msg;
            setTimeout(() => {
              this.resetErrorMsg = undefined;
            }, 3000);
            this.verificationCode = undefined;
            return;
          }

          notification["success"]({
            message: "Password changed successfully!",
          });

          this.handleCancel();
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.no-margin {
  margin-bottom: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
