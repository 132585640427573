import { render, staticRenderFns } from "./project-shift-auths.vue?vue&type=template&id=20becfe8&scoped=true&"
import script from "./project-shift-auths.vue?vue&type=script&lang=js&"
export * from "./project-shift-auths.vue?vue&type=script&lang=js&"
import style0 from "./project-shift-auths.vue?vue&type=style&index=0&id=20becfe8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20becfe8",
  null
  
)

export default component.exports