<template>
  <div class="authorizations-container">
    <div>
      {{ signatureId }}
      Are you sure you want to
      {{ shift.id ? "adjust the" : "save this" }} shift?
    </div>
    <div
      v-if="authorizations && authorizations.length > 0"
      class="flex flex-column gap-2"
    >
      <span
        >Notice that the specified data will result in the creation of
        authorizations:</span
      >
      <a-table
        size="small"
        :data-source="authorizations"
        :columns="columns"
        :pagination="false"
      >
        <span slot="authType" slot-scope="text, record">
          {{ getAuthorizationName(record) }}
        </span>
        <span slot="explanation" slot-scope="text, record">
          {{ getAuthorizationDescription(record) }}
        </span>
      </a-table>
    </div>
    <div class="flex flex-column gap-4">
      <a-radio-group v-model="signatureType">
        <a-radio value="admin">Sign with my signature</a-radio>
        <a-radio value="autosign">Sign with associate signature</a-radio>
        <a-radio value="associate">Send to associate for signature</a-radio>
      </a-radio-group>

      <signature
        v-if="signatureType === 'admin'"
        checkbox-label="I agree that all data is true and correct"
        :associate="signatureAssociate"
        :available-signature="adminAssociateSignature"
        @checked="setSignatureCheckboxValue"
        @save-signature="setSignatureId"
      />
    </div>
    <div class="authorization-buttons">
      <a-button
        type="primary"
        :disabled="signatureType === 'admin' && !signatureCheckboxChecked"
        @click="saveShift"
      >
        Save
      </a-button>
      <a-button type="danger" @click="continueAdjustingShift">
        Cancel
      </a-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import Util from "@/util";
import { Button, Table, Switch, Checkbox, Radio } from "ant-design-vue";
import Signature from "@/components/signature.vue";

export default {
  components: {
    "a-button": Button,
    "a-table": Table,
    "a-switch": Switch,
    "a-checkbox": Checkbox,
    "a-radio-group": Radio.Group,
    "a-radio": Radio,
    signature: Signature,
  },
  mixins: [api],
  props: ["authorizations", "shift"],
  data() {
    return {
      columns: [
        {
          title: "Authorization Type",
          key: "authType",
          scopedSlots: { customRender: "authType" },
        },
        {
          title: "Explanation",
          key: "explanation",
          scopedSlots: { customRender: "explanation" },
        },
      ],
      autoSign: false,
      signatureCheckboxChecked: false,
      signatureId: undefined,

      signatureType: "admin",
    };
  },
  watch: {
    signatureType: {
      handler(newVal) {
        this.signatureId = undefined;

        if (newVal === "autosign") {
          this.autoSign = true;
        } else {
          this.autoSign = false;
        }
      },
    },
  },
  methods: {
    getAuthorizationName(authorizationKey) {
      return (
        this.$store.state.applicationState.extendedShiftAuthTypes.find(
          (item) => item.key === authorizationKey
        ).name ?? ""
      );
    },
    getAuthorizationDescription(authorizationKey) {
      return (
        this.authorizationDescriptionEnum[authorizationKey] ??
        "Unfortunately, explanation is not available"
      );
    },
    saveShift() {
      this.$emit("add", this.autoSign, this.signatureId);
    },
    continueAdjustingShift() {
      this.$emit("cancel");
    },
    setSignatureCheckboxValue(e) {
      this.signatureCheckboxChecked = e;
    },
    setSignatureId(signatureId) {
      this.signatureId = signatureId;
    },
  },
  computed: {
    beforeShiftStartGracePeriod() {
      return this.$store.state.applicationState.timeConstants
        .before_shift_start_grace_period;
    },
    afterShiftEndGracePeriod() {
      return this.$store.state.applicationState.timeConstants
        .after_shift_end_grace_period;
    },
    minWorkDurationHours() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.min_work_duration
      );
    },
    longWorkDurationHours() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.long_work_duration
      );
    },
    lunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    secondLunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.second_lunch_duration
      );
    },

    shiftDuration() {
      let workTime = this.shift.scheduled.end - this.shift.scheduled.start;
      if (this.shift.firstLunchStart && this.shift.firstLunchEnd) {
        workTime -= this.shift.firstLunchEnd - this.shift.firstLunchStart;
      }
      if (this.shift.secondLunchStart && this.shift.secondLunchEnd) {
        workTime -= this.shift.secondLunchEnd - this.shift.secondLunchStart;
      }
      return workTime;
    },

    authorizationDescriptionEnum() {
      return {
        no_lunch_authorization: `Specify the lunch time. Lunch should be on this shift.`,
        no_second_lunch_authorization: `Specify the second lunch time. Second lunch should be on this shift.`,
        late_end_authorization: `Adjust the work end. It should coincide with the scheduled work end.`,
        early_start_authorization: `Adjust the work start. It should coincide with the scheduled work start.`,
        early_lunch_end_authorization: `Increase the duration of lunch. Lunch duration should be
          at least ${this.lunchDuration} minutes.`,
        early_second_lunch_end_authorization: `Increase the duration of second lunch.
          Second lunch duration should be at least ${this.secondLunchDuration} minutes.`,
        no_actual_time_authorization: "Shift added without tracked worked time",
        overtime_authorization: `Authorize overtime. The work hours should not exceed the scheduled hours of work.`,
        edit_shift_authorization: " - ",
        shift_info: " - ",
        outside_work_area_authorization: " - ",
        employee_issue: " - ",
        absence_on_shift_authorization: `Authorize absence on shift. Associate was absent for a valid reason.`,
        late_start_authorization: `Adjust the work start. It should coincide with the scheduled work start.`,
        early_end_authorization: `Adjust the work end. It should coincide with the scheduled work end.`,
        first_lunch_period_authorization: " - ",
        signed_actual_time_discrepancy: `There is a time discrepancy between signed and actual time.`,
        no_rest_break_authorization: " - ",
        associate_not_recognized_authorization: " - ",
        uncompleted_required_tasks_authorization:
          "Not completing critical tasks",
      };
    },
    signatureAssociate() {
      return {
        name: Util.combineAssociateName(
          this.$store.state.applicationState.associate.first_name,
          this.$store.state.applicationState.associate.last_name
        ),
        email: this.$store.state.applicationState.associate.email,
        phone: this.$store.state.applicationState.associate.phone,
      };
    },

    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    },
  },
};
</script>

<style scoped>
.authorizations-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.authorization-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
