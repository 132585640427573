<template>
  <div>
    <h3
      v-if="associateNumber && fullName"
      class="content-header"
      style="padding-left: 24px"
    >
      [{{ associateNumber }}] {{ fullName }}
      <span class="unverified">{{ isVerified ? "" : " | Unverified" }}</span>
    </h3>

    <create-write-up
      :visible="showWriteupForm"
      :associate-number="associateNumber"
      :associate-first-name="firstName"
      :associate-last-name="lastName"
      :associate-role="projectRole"
      @close="closeWriteupForm"
    />

    <history-write-up
      :visible="showHistoryWriteup"
      :associate-number="associateNumber"
      @close="closeWriteupHistory"
    />

    <documents-send
      :is-visible.sync="visibleSendDocumentsModal"
      :loading="loadingSendDocument"
      @send="sendDocuments"
      @close="closeSendDocumentsModal"
    />

    <a-modal
      title="You can clone an associate"
      v-model="openedCloneAssociateSuggestionModal"
      :footer="null"
    >
      <div class="flex flex-column gap-4">
        <div>
          If you need this associate with a different
          {{ differentPropertyName }} - you can clone the associate and give it
          the desired {{ differentPropertyName }}!
        </div>
        <div class="flex gap-2 justify-content-end">
          <a-button
            type="danger"
            @click="cancelChangesAfterCloneAssociateModalOpen"
          >
            Cancel
          </a-button>
          <a-button @click="toggleCloneAssociateSuggestionModal(false)"
            >Change {{ differentPropertyName }}</a-button
          >
          <a-button @click="cloneAssociateFromSuggestionModal" type="primary"
            >Clone this associate</a-button
          >
        </div>
      </div>
    </a-modal>
    <a-row>
      <a-col :span="5">
        <a-menu v-model="activeTab" class="profile-form-menu" mode="inline">
          <a-menu-item
            v-if="isActiveAssociate"
            :key="menuOptions.KPI"
            :disabled="loading"
          >
            <a-icon type="percentage" />
            KPI
          </a-menu-item>
          <a-menu-item
            :class="getErrorClass(menuOptions.PERSONAL)"
            :key="menuOptions.PERSONAL"
            :disabled="loading"
          >
            <a-icon type="user" />
            Personal
          </a-menu-item>
          <a-menu-item
            :class="getErrorClass(menuOptions.JOB)"
            :key="menuOptions.JOB"
            :disabled="loading"
          >
            <a-icon type="bank" />
            Job
          </a-menu-item>
          <a-menu-item
            :class="getErrorClass(menuOptions.PAY)"
            :key="menuOptions.PAY"
            :disabled="loading"
          >
            <a-icon type="credit-card" />
            Pay
          </a-menu-item>
          <a-menu-item v-show="documentsAvailable" :key="menuOptions.DOCUMENTS">
            <a-icon type="file-done" />
            Signed Documents
          </a-menu-item>
          <!-- <a-menu-item :key="menuOptions.ONBOARDING" :disabled="loading">
            <a-icon type="arrow-right" />
            Onboarding
          </a-menu-item>
          <a-menu-item :key="menuOptions.DEPARTURE" :disabled="loading">
            <a-icon type="arrow-left" />
            Departure
          </a-menu-item> -->
          <a-menu-item :key="menuOptions.ALLOWED_AUTHS" :disabled="loading">
            <a-icon type="unordered-list" />
            Allowed Auth Types </a-menu-item
          ><a-menu-item :key="menuOptions.PERMISSIONS" :disabled="loading">
            <a-icon type="file-protect" />
            Permissions
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col :span="19">
        <template v-if="loading">
          <div class="flex align-items-center justify-content-center h-20rem">
            <a-spin />
          </div>
        </template>
        <a-form v-else layout="inline" :form="form">
          <div v-show="personalComponentSelected">
            <div class="content-view">
              <div class="content-view-block">
                <h3>Personal Info</h3>
                <p>
                  <b>First Name <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate" required>
                    <a-input
                      v-decorator="[
                        'firstName',
                        {
                          rules: [
                            {
                              required: true,
                              message: firstNameSuggestionMsg,
                              whitespace: true,
                            },
                            { pattern: namePattern, message: nameErrorMsg },
                          ],
                        },
                      ]"
                      placeholder="Enter first name"
                    />
                  </a-form-item>
                  <span v-else>{{ firstName }}</span>
                </p>
                <p>
                  <b>Last Name <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate" required>
                    <a-input
                      v-decorator="[
                        'lastName',
                        {
                          rules: [
                            {
                              required: true,
                              message: lastNameSuggestionMsg,
                              whitespace: true,
                            },
                            { pattern: namePattern, message: nameErrorMsg },
                          ],
                        },
                      ]"
                      placeholder="Enter last name"
                    />
                  </a-form-item>
                  <span v-else>{{ lastName }}</span>
                </p>
                <p>
                  <b>Email <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate" required>
                    <a-input
                      v-decorator="[
                        'email',
                        {
                          rules: [
                            { required: true, message: emailSuggestionMsg },
                            { type: 'email', message: emailErrorMsg },
                          ],
                        },
                      ]"
                      placeholder="Enter email"
                    />
                  </a-form-item>
                  <span v-else>{{ email }}</span>
                </p>
                <p>
                  <b>Phone <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate" required>
                    <a-input
                      v-decorator="[
                        'phone',
                        {
                          rules: [
                            { required: true, message: phoneSuggestionMsg },
                            {
                              pattern: /^\+\d{10,15}$/,
                              message: phoneErrorMsg,
                            },
                          ],
                        },
                      ]"
                      placeholder="Enter phone"
                    />
                  </a-form-item>
                  <span v-else>{{ phone }}</span>
                </p>
                <p>
                  <b>Associate Number <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate" required>
                    <a-input
                      v-decorator="[
                        'associateNumber',
                        {
                          rules: [
                            {
                              required: true,
                              message: associateNumberSuggestionMsg,
                            },
                          ],
                        },
                      ]"
                      placeholder="Enter associate number"
                      @input="handleAssociateNumberChange"
                    />
                  </a-form-item>
                  <span v-else>{{ associateNumber }}</span>
                </p>
                <p>
                  <b>SSN</b>
                  <a-form-item v-if="editingAssociate">
                    <a-input
                      v-decorator="[
                        'socialSecurityNumber',
                        {
                          rules: [
                            { required: false },
                            { pattern: /^\d{9}$/, message: ssnErrorMsg },
                          ],
                        },
                      ]"
                      placeholder="Enter SSN"
                    />
                  </a-form-item>
                  <span v-else>{{ socialSecurityNumber }}</span>
                </p>
                <p>
                  <b>Birth Date</b>
                  <a-form-item v-if="editingAssociate">
                    <a-date-picker
                      v-decorator="[
                        'birthDate',
                        { rules: [{ required: false }] },
                      ]"
                      placeholder="Select birth date"
                      :format="visualDateFormat"
                      :disabled-date="disabledBirthDate"
                      @openChange="handleBirthDatePickerOpenChange"
                    />
                  </a-form-item>
                  <span v-else>{{ birthDate }}</span>
                </p>
              </div>
              <div class="content-view-block">
                <h3>Address <span class="required-field">*</span></h3>
                <p>
                  <b>Address</b>
                  <a-form-item v-if="editingAssociate">
                    <a-input
                      v-decorator="[
                        'address',
                        {
                          rules: [
                            { required: true, message: 'Please enter address' },
                          ],
                        },
                      ]"
                      placeholder="Enter address"
                    />
                  </a-form-item>
                  <span v-else>{{ address }}</span>
                </p>
                <p>
                  <b>City <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate">
                    <a-input
                      v-decorator="[
                        'city',
                        {
                          rules: [
                            { required: true, message: 'Please enter city' },
                          ],
                        },
                      ]"
                      placeholder="Enter city"
                    />
                  </a-form-item>
                  <span v-else>{{ city }}</span>
                </p>
                <p>
                  <b>State</b>
                  <a-form-item v-if="editingAssociate">
                    <a-select
                      v-decorator="[
                        'state',
                        { rules: [{ required: false }], initialValue: 'CA' },
                      ]"
                      placeholder="Select state"
                    >
                      <a-select-option
                        v-for="state in statesList"
                        :key="state.code"
                        :value="state.code"
                      >
                        {{ state.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <span v-else>{{ state }}</span>
                </p>
                <p>
                  <b>Country</b>
                  <a-form-item v-if="editingAssociate">
                    <a-select
                      v-decorator="[
                        'country',
                        {
                          rules: [
                            { required: true, message: 'Please enter country' },
                          ],
                          initialValue: 'US',
                        },
                      ]"
                      placeholder="Select country"
                    >
                      <a-select-option
                        v-for="country in countriesList"
                        :key="country.code"
                        :value="country.code"
                      >
                        {{ country.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <span v-else>{{ country }}</span>
                </p>
                <p>
                  <b>Zip <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate">
                    <a-input
                      v-decorator="[
                        'zipcode',
                        {
                          rules: [
                            { required: false },
                            {
                              pattern: zipcodePattern,
                              message: zipcodePatternErrorMsg,
                            },
                          ],
                        },
                      ]"
                      placeholder="Enter zip"
                    />
                  </a-form-item>
                  <span v-else>{{ zipcode }}</span>
                </p>
              </div>
            </div>
          </div>
          <div v-show="jobComponentSelected">
            <div class="content-view flex gap-6">
              <div class="flex flex-column gap-4">
                <div>
                  <h3>Job Classification Info</h3>
                  <p>
                    <b
                      >Job Classification
                      <span class="required-field">*</span></b
                    >
                    <a-form-item v-if="editingAssociate" required>
                      <a-select
                        v-decorator="[
                          'projectRole',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please select a job classification!',
                              },
                            ],
                          },
                        ]"
                        placeholder="Select job classification"
                      >
                        <a-select-option
                          v-for="role in projectRoles"
                          :key="role.id"
                          :value="role.id"
                        >
                          {{ role.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <span v-else>{{ projectRole }}</span>
                  </p>
                  <p>
                    <b>Schedule Type <span class="required-field">*</span></b>
                    <a-form-item v-if="editingAssociate" required>
                      <a-select
                        v-decorator="[
                          'scheduleType',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please select a schedule type!',
                              },
                            ],
                          },
                        ]"
                        placeholder="Select ST"
                      >
                        <a-select-option
                          v-for="type in scheduleTypes"
                          :key="type.name"
                          :value="type.name"
                          >{{ type.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <span v-else>{{ scheduleType }}</span>
                  </p>
                </div>

                <div>
                  <h3>Company Params</h3>
                  <div class="grid">
                    <div class="col-10">
                      <p class="text-lg font-bold m-0">
                        Shift Actions Autocomplete
                      </p>
                      <p class="m-0">
                        The associate will automatically start and end first and
                        second meal break and finish work to prevent policy
                        violations in your company. Associate will self-sign the
                        shift and have the ability to change tracked work hours.
                      </p>
                    </div>
                    <a-form-item class="col-2 m-0" v-if="editingAssociate" required>
                      <a-switch
                        v-decorator="[
                          'shiftActionAutocomplete',
                          {
                            valuePropName: 'checked',
                          },
                        ]"
                      />
                    </a-form-item>
                    <span class="col-2 text-right m-0" v-else>{{
                      shiftActionAutocomplete ? "Yes" : "No"
                    }}</span>

                    <div class="col-10 pr-4">
                      <p class="text-lg font-bold m-0">
                        Auto Approve Payment Request
                      </p>
                      <p class="m-0">
                        Payment requests from this associate will be approved
                        automatically.
                      </p>
                    </div>
                    <a-form-item class="col-2 m-0" v-if="editingAssociate" required>
                      <a-switch
                        v-decorator="[
                          'autoApprovePaymentRequest',
                          {
                            valuePropName: 'checked',
                          },
                        ]"
                      />
                    </a-form-item>
                    <span class="col-2 text-right m-0" v-else>{{
                      autoApprovePaymentRequest ? "Yes" : "No"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-column gap-4">
                <activity-info
                  :is-active="isActiveAssociate"
                  :termination="termination"
                  :associate-id="associateId"
                  :associate-number="associateNumber"
                  @reset="fetchDataAndInitForm"
                />
                <suspension-info
                  v-show="isActiveAssociate"
                  :suspension="suspension"
                  :associate-number="associateNumber"
                  @reset="fetchDataAndInitForm"
                />
              </div>
            </div>
          </div>
          <div v-show="payComponentSelected">
            <div class="content-view">
              <div class="content-view-block pay-block">
                <h3>Payment Info</h3>
                <p>
                  <b>Type <span class="required-field">*</span></b>
                  <a-form-item v-if="editingAssociate">
                    <a-select
                      v-decorator="[
                        'paymentType',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Please select a payment type!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Select PT"
                    >
                      <a-select-option
                        v-for="type in paymentTypes"
                        :key="type.id"
                        :value="type.id"
                      >
                        {{ type.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <span v-else>{{ paymentType }}</span>
                </p>
                <p v-if="!isPaymentMethodSalary">
                  <b>Status</b>
                  <a-form-item v-if="editingAssociate">
                    <a-select
                      v-model="paymentStatus"
                      placeholder="Select PS"
                      @change="handleFieldChange"
                    >
                      <a-select-option
                        v-for="status in paymentStatuses"
                        :key="status.id"
                        :value="status.id"
                      >
                        {{ status.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <span v-else>{{ paymentStatusToDisplay }}</span>
                </p>
                <template v-if="isContractor">
                  <p :class="{ 'subtype-selector-p': editingAssociate }">
                    <b>Subtype <span class="required-field">*</span></b>
                    <template v-if="editingAssociate">
                      <a-form-item>
                        <a-radio-group
                          class="subtype-selector"
                          v-model="isIndependentContractor"
                        >
                          <a-radio :style="radioStyle" :value="true">
                            Independent Contractor
                          </a-radio>
                          <a-radio :style="radioStyle" :value="false">
                            Agency
                          </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </template>
                    <span v-else>
                      {{
                        isIndependentContractor
                          ? "Independent Contractor"
                          : "Agency"
                      }}
                    </span>
                  </p>
                  <p>
                    <b>Agency <span class="required-field">*</span></b>
                    <a-form-item v-if="editingAssociate">
                      <a-select
                        placeholder="Please select agency"
                        :value="selectedAgency ? selectedAgency.id : undefined"
                        @change="handleAgencyChange"
                      >
                        <a-select-option
                          v-for="agency in filteredAgencies"
                          :key="agency.id"
                          :value="agency.id"
                        >
                          {{ agency.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <span v-else-if="isContractor && !editingAssociate">
                      {{ agency }}
                    </span>
                  </p>
                  <p>
                    <b>Hourly Rate, $ <span class="required-field">*</span></b>
                    <a-form-item v-if="editingAssociate">
                      <a-input-number
                        v-decorator="[
                          'rate',
                          { rules: [{ required: true }], initialValue: 0 },
                        ]"
                        placeholder="Enter rate"
                        :min="0"
                      />
                    </a-form-item>
                    <span v-else>{{ rate }}</span>
                  </p>
                </template>
                <template v-if="isEmployee">
                  <p v-if="isFullTimeOrPartTimeEmployee">
                    <b>Method</b>
                    <a-form-item v-if="editingAssociate">
                      <a-select
                        v-model="paymentMethod"
                        placeholder="Select PM"
                        @change="handlePaymentMethodChange"
                      >
                        <a-select-option
                          v-for="method in paymentMethods"
                          :key="method.id"
                          :value="method.id"
                        >
                          {{ method.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <span v-else>{{ paymentMethodToDisplay }}</span>
                  </p>
                  <p>
                    <b
                      >{{
                        isPaymentMethodSalary
                          ? "Salary Per Week"
                          : "Hourly Rate"
                      }}, $</b
                    >
                    <a-form-item v-if="editingAssociate">
                      <a-input-number
                        v-decorator="[
                          'rate',
                          { rules: [{ required: false }], initialValue: 0 },
                        ]"
                        placeholder="Enter rate"
                        :min="0"
                      />
                    </a-form-item>
                    <span v-else>{{ rate }}</span>
                  </p>
                </template>
              </div>
            </div>
          </div>
          <div v-show="documentsComponentSelected" class="documents">
            <div class="flex flex-column gap-4">
              <h3 class="m-0">Signed Documents</h3>
              <div class="flex gap-2">
                <a-button type="primary" @click="downloadAllDocuments()">
                  Download All
                  <a-icon type="download" />
                </a-button>
                <a-button @click="openSendDocumentsModal()">
                  Send All To Email
                </a-button>
              </div>
              <a-table
                :columns="columns"
                :data-source="documents"
                :loading="loading"
                rowKey="id"
              >
                <span slot="date" slot-scope="text, record">
                  {{ formatDateString(record.signature.signed_date) }}
                </span>
                <span slot="actions" slot-scope="text, record">
                  <div class="flex gap-2 justify-content-center">
                    <a-button
                      type="primary"
                      @click="downloadDocument(record.id)"
                    >
                      Download
                      <a-icon type="download" />
                    </a-button>
                    <a-button @click="openSendDocumentsModal(record.id)">
                      Send To Email
                    </a-button>
                  </div>
                </span>
              </a-table>
            </div>
          </div>
          <div v-show="onboardingComponentSelected">
            <div class="flex flex-column gap-2 pl-4">
              <template v-if="!onboardingInfo">
                <div>No information about onboarding was found</div>
              </template>
              <template
                v-else-if="
                  onboardingInfo.steps && onboardingInfo.steps.length > 0
                "
              >
                <h3>Onboarding</h3>
                <div class="flex flex-column gap-1">
                  <div class="flex flex-row gap-2 mb-2 ml-1">
                    <b>Status:</b>
                    <div>{{ onboardingCompletionStatus }}</div>
                  </div>
                  <div class="flex flex-row gap-2 mb-2 ml-1">
                    <b>Last Update:</b>
                    <div>{{ onboardingUpdateDate }}</div>
                  </div>
                </div>
                <a-table
                  :data-source="onboardingInfo.steps"
                  :columns="sequenceColumns"
                  :pagination="false"
                  size="small"
                >
                  <span slot="name" slot-scope="text, record">
                    {{ record.body.name }}
                  </span>
                  <span slot="type" slot-scope="text, record">
                    {{ transformSequenceStepType(record.type) }}
                  </span>
                  <span slot="status" slot-scope="text, record">
                    {{ capitalize(record.status) }}
                  </span>
                  <span slot="attachedFiles" slot-scope="text, record">
                    <template
                      v-if="record.body.files && record.body.files.length > 0"
                    >
                      <div class="flex flex-column gap-2">
                        <div
                          v-for="(file, index) in record.body.files"
                          :key="file.id"
                        >
                          <a :href="file.url" target="_blank"
                            >File #{{ index + 1 }}</a
                          >
                        </div>
                      </div>
                    </template>
                    <template v-else> - </template>
                  </span>
                </a-table>
              </template>
              <a-button
                class="w-3 mt-2"
                :loading="sendingToOnboarding"
                type="primary"
                @click="sendAssociateToOnboarding"
              >
                Send Associate to Onboarding
              </a-button>
            </div>
          </div>
          <div v-show="departureComponentSelected">
            <div class="flex flex-column gap-2 pl-4">
              <template v-if="!departureInfo">
                <div>No information about departure was found</div>
              </template>
              <template
                v-else-if="
                  departureInfo.steps && departureInfo.steps.length > 0
                "
              >
                <h3>Onboarding</h3>
                <div class="flex flex-column gap-1">
                  <div class="flex flex-row gap-2 mb-2 ml-1">
                    <b>Status:</b>
                    <div>{{ departureCompletionStatus }}</div>
                  </div>
                  <div class="flex flex-row gap-2 mb-2 ml-1">
                    <b>Last Update:</b>
                    <div>{{ departureUpdateDate }}</div>
                  </div>
                </div>
                <a-table
                  :data-source="departureInfo.steps"
                  :columns="sequenceColumns"
                  :pagination="false"
                  size="small"
                >
                  <span slot="name" slot-scope="text, record">
                    {{ record.body.name }}
                  </span>
                  <span slot="type" slot-scope="text, record">
                    {{ transformSequenceStepType(record.type) }}
                  </span>
                  <span slot="status" slot-scope="text, record">
                    {{ capitalize(record.status) }}
                  </span>
                  <span slot="attachedFiles" slot-scope="text, record">
                    <template
                      v-if="record.body.files && record.body.files.length > 0"
                    >
                      <div class="flex flex-column gap-2">
                        <div
                          v-for="(file, index) in record.body.files"
                          :key="file.id"
                        >
                          <a :href="file.url" target="_blank"
                            >File #{{ index + 1 }}</a
                          >
                        </div>
                      </div>
                    </template>
                    <template v-else> - </template>
                  </span>
                </a-table>
              </template>
            </div>
          </div>
          <div v-show="allowedAuthTypesComponentSelected">
            <div class="auth-types">
              <h3>Allowed Authorization Types</h3>
              <a-table
                size="small"
                :data-source="extendedAuthTypes"
                :columns="authTypesColumns"
                :row-selection="
                  editingAssociate
                    ? {
                        selectedRowKeys: selectedAuthTypes,
                        onChange: onSelectedAuthTypesChange,
                      }
                    : null
                "
              >
                <span slot="group" slot-scope="text, record">
                  {{
                    isPayrollKey(record.key) ? "Payroll" : "Disciplinary Action"
                  }}
                </span>
              </a-table>
            </div>
          </div>
          <div v-show="permissionsComponentSelected">
            <permissions-info
              :permissions="selectedPermissions"
              :is-disabled="!editingAssociate"
              @change="onPermissionsChange"
            />
          </div>
          <div class="kpi" v-if="kpiComponentSelected">
            <div class="kpi-controls flex gap-3 align-items-center">
              <div class="flex gap-2">
                <b>In Range</b>
                <a-switch v-model="showRangeKpi" />
              </div>
              <div v-if="showRangeKpi" class="flex gap-2">
                <a-date-picker
                  v-model="kpiFrom"
                  :disabled-date="disabledStartDate"
                  :format="visualDateFormat"
                  placeholder="From"
                  :disabled="loading"
                />
                <a-date-picker
                  v-model="kpiTo"
                  :disabled-date="disabledEndDate"
                  :format="visualDateFormat"
                  placeholder="To"
                  :disabled="loading"
                />
              </div>
            </div>
            <associate-kpi :kpi-stats="kpiStats" />
          </div>
          <div v-if="showEditButtons" class="view-button-group">
            <template v-if="editingAssociate">
              <a-button
                type="primary"
                :loading="loading"
                :disabled="disableSave"
                @click="handleSubmit"
              >
                {{ isVerified ? "Save" : "Save and Verify" }}
              </a-button>
              <a-button type="danger" :loading="loading" @click="cancelEdit">
                Cancel
              </a-button>
            </template>
            <template v-else>
              <a-button type="primary" @click="startEdit"> Edit </a-button>
              <template v-if="personalComponentSelected">
                <a-button type="primary" @click="openWriteupForm">
                  Create Write-Up
                </a-button>
              </template>
              <template v-if="personalComponentSelected">
                <a-button type="primary" @click="openWriteupHistory">
                  Write-up history
                </a-button>
              </template>
              <template v-if="personalComponentSelected">
                <a-button @click="emitCloneAssociate"> Clone </a-button>
              </template>
            </template>
          </div>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  Form,
  Icon,
  Menu,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Switch,
  Input,
  InputNumber,
  Table,
  Dropdown,
  Spin,
  notification,
  Modal,
  Radio,
} from "ant-design-vue";

import Util from "@/util";
import api from "@/api";

import moment from "moment-timezone";
import ConstData from "@/helpers/const-data";
import AssociateKpi from "@/components/associate-kpi.vue";
import CreateWriteUp from "@/components/create-write-up.vue";
import HistoryWriteUp from "@/components/write-up/write-up-history-modal.vue";
import ActivityInfo from "@/modules/profile/components/profile-activity-info.vue";
import SuspensionInfo from "@/modules/profile/components/profile-suspension-info.vue";
import { sequenceStepTypes } from "@/const";
import ProfilePermissions from "@/modules/profile/components/profile-permissions.vue";
import { createNamespacedHelpers } from "vuex";
import DocumentSendModal from "@/modules/document/components/document-send-modal.vue";

const { mapActions: documentActions } = createNamespacedHelpers("document");

const PaymentType = Object.freeze({
  EMPLOYEE: 3,
  CONTRACTOR: 6,
  VOLUNTEER: 5,
});

const PaymentStatus = Object.freeze({
  ON_CALL: 0,
  FULL_TIME: 1,
  PART_TIME: 2,
  TERMINATED: 3,
});

const PaymentMethod = Object.freeze({
  HOURLY: 0,
  SALARY: 1,
});

const MenuOptions = Object.freeze({
  PERSONAL: "personal",
  JOB: "job",
  PAY: "pay",
  DOCUMENTS: "documents",
  KPI: "kpi",
  ONBOARDING: "onboarding",
  DEPARTURE: "departure",
  ALLOWED_AUTHS: "Allowed Auth Types",
  PERMISSIONS: "permissions",
});

const personalMenuKeys = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "associateNumber",
  "socialSecurityNumber",
  "birthDate",
  "address",
  "city",
  "state",
  "country",
  "zipcode",
];

const payMenuKeys = ["paymentType", "paymentStatus", "paymentMethod", "rate"];

const jobMenuKeys = [
  "projectRole",
  "scheduleType",
  "shiftActionAutocomplete",
  "autoApprovePaymentRequest",
];

const errorMap = {
  [MenuOptions.PERSONAL]: personalMenuKeys,
  [MenuOptions.PAY]: payMenuKeys,
  [MenuOptions.JOB]: jobMenuKeys,
};

export default {
  components: {
    "associate-kpi": AssociateKpi,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-row": Row,
    "a-col": Col,
    "a-button": Button,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-date-picker": DatePicker,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-table": Table,
    "a-dropdown": Dropdown,
    "a-spin": Spin,
    "a-textarea": Input.TextArea,
    "a-modal": Modal,
    "a-radio": Radio,
    "a-radio-group": Radio.Group,
    "create-write-up": CreateWriteUp,
    "history-write-up": HistoryWriteUp,
    "activity-info": ActivityInfo,
    "suspension-info": SuspensionInfo,
    "permissions-info": ProfilePermissions,
    "documents-send": DocumentSendModal,
  },
  props: {
    associateNumber: {
      type: Number | String,
      required: true,
    },
  },
  mixins: [api],
  data() {
    return {
      activeTab: [MenuOptions.KPI],

      form: undefined,
      formFieldsBackup: undefined,

      isEmployee: false,
      isContractor: false,
      isActiveAssociate: false,

      menuOptions: MenuOptions,

      loading: false,

      firstNameSuggestionMsg: "Please enter your first name",
      lastNameSuggestionMsg: "Please enter your last name",
      emailSuggestionMsg: "Please enter your email",
      phoneSuggestionMsg: "Please enter your phone number",
      associateNumberSuggestionMsg: "Please enter your associate number",

      nameErrorMsg:
        "The first name or last name must be between 2 to 25 characters long and must be capitalized. " +
        "Use letters (Latin alphabet), spaces, and hyphens only. ",
      emailErrorMsg: "Incorrect email",
      phoneErrorMsg:
        "Invalid phone number. It should start with a + and contain 10 to 15 digits.",
      associateNumberErrorMsg: "Associate number must be exactly 6 digits.",
      zipcodePatternErrorMsg: "Zipcode must be exactly 5 digits.",
      ssnErrorMsg: "Enter the SSN as 9 digits without hyphens",

      statesList: ConstData.usStates,
      countriesList: ConstData.countries,

      namePattern: /^[A-Z][a-zA-Z\s-]{1,24}$/,
      associateNumberPattern: /^\d{6}$/,
      zipcodePattern: /^\d{5}$/,

      scheduleTypes: [
        { name: "fixed", label: "Fixed" },
        { name: "self_schedule", label: "Self Schedule" },
        { name: "free", label: "Free" },
      ],
      paymentTypes: [
        { id: PaymentType.EMPLOYEE, name: "Employee" },
        { id: PaymentType.CONTRACTOR, name: "Contractor" },
        { id: PaymentType.VOLUNTEER, name: "Volunteer" },
      ],
      paymentStatuses: [
        { id: PaymentStatus.FULL_TIME, label: "Full Time" },
        { id: PaymentStatus.PART_TIME, label: "Part Time" },
        { id: PaymentStatus.ON_CALL, label: "On Call / Per Diem" },
        { id: PaymentStatus.TERMINATED, label: "Terminated" },
      ],
      paymentMethods: [
        { id: PaymentMethod.HOURLY, label: "Hourly" },
        { id: PaymentMethod.SALARY, label: "Salary" },
      ],

      paymentStatus: undefined,
      paymentMethod: undefined,
      selectedAgency: undefined,
      agencies: [],

      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 80,
          sorter: (a, b) => a.id - b.id,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) =>
            a.name == null
              ? -1
              : b.name == null
              ? 1
              : a.name.localeCompare(b.name),
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          align: "center",
          width: 150,
          scopedSlots: { customRender: "date" },
          sorter: (a, b) =>
            a.signature.signed_date == null
              ? -1
              : b.signature.signed_date == null
              ? 1
              : moment(a.signature.signed_date).valueOf() -
                moment(b.signature.signed_date).valueOf(),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          align: "center",
          width: 300,
          scopedSlots: { customRender: "actions" },
        },
      ],

      fieldsTouched: false,
      documents: [],

      editingAssociate: false,

      kpiStats: undefined,
      showRangeKpi: false,
      kpiFrom: undefined,
      kpiTo: undefined,

      openedCloneAssociateSuggestionModal: false,

      formBefore: undefined,

      differentPropertyName: undefined,
      ptChangedNow: false,

      isIndependentContractor: undefined,

      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },

      onboardingInfo: undefined,
      departureInfo: undefined,

      isVerified: true,

      sequenceColumns: [
        {
          title: "Name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Attached Files",
          key: "attachedFiles",
          scopedSlots: { customRender: "attachedFiles" },
        },
      ],

      associateId: undefined,
      sendingToOnboarding: false,

      authTypesColumns: [
        {
          title: "Name",
          key: "name",
          dataIndex: "name",
        },
        {
          title: "Group",
          key: "group",
          scopedSlots: { customRender: "group" },
        },
        {
          title: "Description",
          key: "status",
          dataIndex: "description",
        },
      ],
      selectedAuthTypes: [],

      showWriteupForm: false,
      showHistoryWriteup: false,
      visibleSendDocumentsModal: false,

      sendDocumentId: undefined,

      loadingSendDocument: false,

      selectedPermissions: [],

      errors: [],
    };
  },
  watch: {
    associateNumber(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.fetchDataAndInitForm();
      }
    },
    kpiFrom() {
      this.loadKpi();
    },
    kpiTo() {
      this.loadKpi();
    },
    showRangeKpi() {
      if (!this.showRangeKpi && (this.kpiFrom || this.kpiTo)) {
        this.kpiFrom = undefined;
        this.kpiTo = undefined;

        this.fetchDataAndInitForm();
      }
    },
    isIndependentContractor(newVal, prevVal) {
      if (newVal !== prevVal && prevVal !== undefined) {
        this.selectedAgency.id = undefined;
      }
    },
  },
  computed: {
    fullName() {
      if (this.form) {
        return Util.combineAssociateName(
          this.form.getFieldValue("firstName") ?? "",
          this.form.getFieldValue("lastName") ?? ""
        );
      }

      return "";
    },
    documentsAvailable() {
      return this.documents.length > 0;
    },

    personalComponentSelected() {
      return this.activeTab.includes(this.menuOptions.PERSONAL);
    },
    jobComponentSelected() {
      return this.activeTab.includes(this.menuOptions.JOB);
    },
    payComponentSelected() {
      return this.activeTab.includes(this.menuOptions.PAY);
    },
    documentsComponentSelected() {
      return this.activeTab.includes(this.menuOptions.DOCUMENTS);
    },
    kpiComponentSelected() {
      return this.activeTab.includes(this.menuOptions.KPI);
    },
    onboardingComponentSelected() {
      return this.activeTab.includes(this.menuOptions.ONBOARDING);
    },
    departureComponentSelected() {
      return this.activeTab.includes(this.menuOptions.DEPARTURE);
    },
    permissionsComponentSelected() {
      return this.activeTab.includes(this.menuOptions.PERMISSIONS);
    },
    onboardingOrDepartureComponentSelected() {
      return (
        this.onboardingComponentSelected || this.departureComponentSelected
      );
    },
    allowedAuthTypesComponentSelected() {
      return this.activeTab.includes(this.menuOptions.ALLOWED_AUTHS);
    },
    showEditButtons() {
      return !(
        this.documentsComponentSelected ||
        this.kpiComponentSelected ||
        this.onboardingOrDepartureComponentSelected
      );
    },

    isPaymentMethodSalary() {
      return this.paymentMethod === PaymentMethod.SALARY;
    },

    isFullTime() {
      return this.paymentStatus === PaymentStatus.FULL_TIME;
    },
    isPartTime() {
      return this.paymentStatus === PaymentStatus.PART_TIME;
    },

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    visualDateTimeFormat() {
      return this.$store.state.applicationState.dateTimeFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    projectRoles() {
      return this.$store.state.applicationState.projectRoles;
    },

    isContractorOrEmployeePaymentTypeSelected() {
      return this.isEmployee || this.isContractor;
    },
    isFullTimeEmployee() {
      if (this.form) {
        return this.isEmployee && this.isFullTime;
      }

      return false;
    },
    isPartTimeEmployee() {
      if (this.form) {
        return this.isEmployee && this.isPartTime;
      }

      return false;
    },
    isFullTimeOrPartTimeEmployee() {
      return this.isFullTimeEmployee || this.isPartTimeEmployee;
    },
    currentPaymentType() {
      switch (true) {
        case this.isEmployee:
          return PaymentType.EMPLOYEE;
        case this.isContractor:
          return PaymentType.CONTRACTOR;
        default:
          return PaymentType.VOLUNTEER;
      }
    },

    disableSave() {
      return !this.form || (!this.fieldsTouched && this.isVerified);
    },

    agencySelectedAndHourRateNotChosen() {
      return (
        this.currentPaymentType === PaymentType.CONTRACTOR &&
        this.isSelectedAgencyNotSpecified
      );
    },

    isSelectedAgencyNotSpecified() {
      return !this.selectedAgency || !this.selectedAgency.id;
    },

    firstName() {
      return this.form && this.form.getFieldValue("firstName")
        ? this.form.getFieldValue("firstName")
        : "-";
    },
    lastName() {
      return this.form && this.form.getFieldValue("lastName")
        ? this.form.getFieldValue("lastName")
        : "-";
    },
    email() {
      return this.form && this.form.getFieldValue("email")
        ? this.form.getFieldValue("email")
        : "-";
    },
    phone() {
      return this.form && this.form.getFieldValue("phone")
        ? this.form.getFieldValue("phone")
        : "-";
    },
    socialSecurityNumber() {
      return this.form && this.form.getFieldValue("socialSecurityNumber")
        ? this.form.getFieldValue("socialSecurityNumber")
        : "-";
    },
    birthDate() {
      const birthDate =
        this.form && this.form.getFieldValue("birthDate")
          ? this.form.getFieldValue("birthDate")
          : null;
      return birthDate ? birthDate.format(this.visualDateFormat) : "-";
    },
    address() {
      return this.form && this.form.getFieldValue("address")
        ? this.form.getFieldValue("address")
        : "-";
    },
    city() {
      return this.form && this.form.getFieldValue("city")
        ? this.form.getFieldValue("city")
        : "-";
    },
    state() {
      const stateCode =
        this.form && this.form.getFieldValue("state")
          ? this.form.getFieldValue("state")
          : "-";
      const stateObject = this.statesList.find(
        (state) => state.code == stateCode
      );

      return stateObject ? stateObject.name : stateCode;
    },
    country() {
      const countryCode =
        this.form && this.form.getFieldValue("country")
          ? this.form.getFieldValue("country")
          : "-";
      const countryObject = this.countriesList.find(
        (country) => country.code == countryCode
      );

      return countryObject ? countryObject.name : countryCode;
    },
    zipcode() {
      return this.form && this.form.getFieldValue("zipcode")
        ? this.form.getFieldValue("zipcode")
        : "-";
    },
    projectRole() {
      const projectRoleId =
        this.form && this.form.getFieldValue("projectRole")
          ? this.form.getFieldValue("projectRole")
          : "-";
      const projectRoleObject = this.projectRoles.find(
        (role) => role.id == projectRoleId
      );

      return projectRoleObject ? projectRoleObject.name : projectRoleId;
    },
    scheduleType() {
      const scheduleTypeName =
        this.form && this.form.getFieldValue("scheduleType")
          ? this.form.getFieldValue("scheduleType")
          : "-";
      const scheduleTypeObject = this.scheduleTypes.find(
        (scheduleType) => scheduleType.name == scheduleTypeName
      );

      return scheduleTypeObject ? scheduleTypeObject.label : scheduleTypeName;
    },
    shiftActionAutocomplete() {
      return this.form && this.form.getFieldValue("shiftActionAutocomplete")
        ? this.form.getFieldValue("shiftActionAutocomplete")
        : null;
    },
    autoApprovePaymentRequest() {
      return this.form && this.form.getFieldValue("autoApprovePaymentRequest")
        ? this.form.getFieldValue("autoApprovePaymentRequest")
        : null;
    },
    paymentType() {
      const paymentTypeId =
        this.form && this.form.getFieldValue("paymentType")
          ? this.form.getFieldValue("paymentType")
          : "-";
      const paymentTypeObject = this.paymentTypes.find(
        (paymentType) => paymentType.id == paymentTypeId
      );

      return paymentTypeObject ? paymentTypeObject.name : paymentTypeId;
    },
    paymentStatusToDisplay() {
      const paymentStatusObject = this.paymentStatuses.find(
        (paymentStatus) => paymentStatus.id == this.paymentStatus
      );

      return paymentStatusObject
        ? paymentStatusObject.label
        : this.paymentStatus ?? "-";
    },
    paymentMethodToDisplay() {
      const paymentMethodObject = this.paymentMethods.find(
        (paymentMethod) => paymentMethod.id == this.paymentMethod
      );

      return paymentMethodObject
        ? paymentMethodObject.label
        : this.paymentMethod ?? "-";
    },
    agency() {
      return this.selectedAgency ? this.selectedAgency.name : "-";
    },
    rate() {
      return this.form && this.form.getFieldValue("rate") != null
        ? this.form.getFieldValue("rate")
        : "-";
    },

    suspension() {
      return this.form ? this.form.getFieldValue("suspension") : undefined;
    },

    termination() {
      return this.form ? this.form.getFieldValue("termination") : undefined;
    },

    kpiFromString() {
      if (this.kpiFrom === null) {
        return null;
      }

      return Util.convertDateTimeToUTC(this.kpiFrom);
    },
    kpiToString() {
      if (this.kpiTo === null) {
        return null;
      }

      return Util.convertDateTimeToUTC(this.kpiTo);
    },

    filteredAgencies() {
      if (!this.agencies) {
        return [];
      }

      return this.agencies.filter(
        (agency) =>
          agency.type ==
          (this.isIndependentContractor ? "independent_contractor" : "agency")
      );
    },

    onboardingUpdateDate() {
      return Util.convertDateTimeToCurrentTimeZoneWithFormat(
        this.onboardingInfo.updated_at,
        this.visualDateTimeFormat
      );
    },
    onboardingCompletionStatus() {
      return Util.capitalize(this.onboardingInfo.completion_status);
    },

    departureUpdateDate() {
      return Util.convertDateTimeToCurrentTimeZoneWithFormat(
        this.departureInfo.updated_at,
        this.visualDateTimeFormat
      );
    },
    departureCompletionStatus() {
      return Util.capitalize(this.departureInfo.completion_status);
    },

    extendedAuthTypes() {
      let authTypes = this.$store.state.applicationState.extendedShiftAuthTypes;

      if (!authTypes) {
        authTypes = [];
      }
      if (!this.editingAssociate) {
        authTypes = authTypes.filter((record) =>
          this.selectedAuthTypes.includes(record.key)
        );
      }

      return authTypes;
    },
  },
  methods: {
    ...documentActions([
      "downloadAssociateDocument",
      "downloadAllAssociateDocuments",
      "sendAssociateDocument",
      "sendAllAssociateDocument",
    ]),
    formatDateString(dateString) {
      return moment(dateString).format("MM/DD/YYYY");
    },
    capitalize: Util.capitalize,
    handlePaymentTypeChange(type) {
      if (type != this.currentPaymentType) {
        this.ptChangedNow = true;
        this.setPaymentTypeBooleans(type);
        this.changePaymentInfoDependOnSelectedPaymentType(type);

        this.differentPropertyName = "payment type";
        this.toggleCloneAssociateSuggestionModal(true);
      }
    },
    handleRoleChange(currentRole) {
      if (this.form.getFieldValue("projectRole") === currentRole) {
        return;
      }
      this.differentPropertyName = "job classification";
      this.toggleCloneAssociateSuggestionModal(true);
    },
    handlePaymentMethodChange() {
      if (this.isPaymentMethodSalary) {
        this.paymentStatus = PaymentStatus.FULL_TIME;
      }

      this.fieldsTouched = true;
      this.differentPropertyName = "payment method";
      this.toggleCloneAssociateSuggestionModal(true);
    },
    handleAgencyChange(agencyId) {
      const selectedAgency = this.filteredAgencies.find(
        (agency) => agency.id === agencyId
      );
      this.selectedAgency = selectedAgency || null;
      this.fieldsTouched = true;
      if (this.ptChangedNow) {
        return;
      }

      this.differentPropertyName = "legal entity";
      this.toggleCloneAssociateSuggestionModal(true);
    },
    getPaymentStatus() {
      if (this.isPaymentMethodSalary) {
        return PaymentStatus.FULL_TIME;
      }
      if (this.isContractor) {
        return PaymentStatus.ON_CALL;
      }

      return this.paymentStatus;
    },
    getPaymentMethod() {
      if (this.isContractor) {
        return PaymentMethod.HOURLY;
      }

      return this.paymentMethod;
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (
            this.currentPaymentType === PaymentType.CONTRACTOR &&
            !this.selectedAgency
          ) {
            notification["warning"]({
              message: "Associate Save Error",
              description:
                "For the Contractor payment type you must select Agency",
            });
            this.activeTab = [MenuOptions.PAY];
            return;
          }

          this.loading = true;
          this.apiSetAssociate(
            this.associateNumber,
            values.firstName,
            values.lastName,
            values.socialSecurityNumber,
            values.phone,
            values.address,
            values.country ? values.country.toLowerCase() : null,
            values.city,
            values.state ? values.state.toLowerCase() : null,
            values.zipcode,
            this.getPaymentStatus(),
            values.email,
            values.birthDate
              ? values.birthDate.format(this.serverDateFormat)
              : null,
            values.projectRole,
            this.getPaymentMethod(),
            values.scheduleType,
            Util.convertMoneyToDb(values.rate),
            values.paymentType,
            values.associateNumber,
            this.currentPaymentType === PaymentType.CONTRACTOR
              ? this.selectedAgency
              : null,
            values.shiftActionAutocomplete,
            values.autoApprovePaymentRequest,
            this.selectedAuthTypes,
            this.selectedPermissions,
            this.isActiveAssociate
          )
            .then(({ data }) => {
              if (data.error_code && data.error_code !== "0") {
                notification["error"]({
                  message: "Error",
                  description:
                    "An error occurred while saving the associate data. Please try again later",
                });
                return;
              }

              notification["success"]({
                message: "Success",
                description: "Associate data saved successfully",
              });

              if (values.associateNumber != this.associateNumber) {
                this.associateNumber = values.associateNumber;
                this.$emit("update:associateNumber", values.associateNumber);
              }

              this.fetchDataAndInitForm();
              this.$emit("edited");
            })
            .catch(() => {
              notification["error"]({
                message: "Error",
                description:
                  "An error occurred while saving the associate data. Please try again later",
              });
              return;
            })
            .finally(() => {
              this.loading = false;
              this.cancelEdit();
            });
        } else {
          this.handleFormError(Object.keys(err));
        }
      });
    },
    handleAssociateNumberChange() {
      const associateNumberValue = this.form.getFieldValue("associateNumber");

      if (
        associateNumberValue &&
        this.associateNumber != associateNumberValue
      ) {
        this.apiIsAssociateNumberBusy(associateNumberValue).then((response) => {
          if (response.data.is_associate_number_busy) {
            this.form.setFields({
              associateNumber: {
                value: "",
                errors: [
                  new Error(
                    `Associate number ${associateNumberValue} is busy. Set another one`
                  ),
                ],
              },
            });
          }
        });
      }
    },
    handleBirthDatePickerOpenChange() {
      if (!this.form.getFieldValue("birthDate")) {
        this.form.setFieldsValue({
          birthDate: this.calculateDefaultDate(),
        });
      }
    },

    // errors handling
    handleFormError(errorFields) {
      this.errors = errorFields;

      const errorSections = this.getErrorSections();

      notification["error"]({
        message: "Associate Save Error",
        description: `You need to fill in all required fields in the following sections: ${errorSections.join(
          ", "
        )}.`,
      });
    },

    getErrorClass(menuKey) {
      return this.hasErrorInSection(menuKey) ? "menu-item-error" : "";
    },

    hasErrorInSection(menuKey) {
      return this.errors.some((errorField) =>
        errorMap[menuKey]?.includes(errorField)
      );
    },

    getErrorSections() {
      return Object.keys(errorMap)
        .filter((menuKey) => this.hasErrorInSection(menuKey))
        .map((menuKey) => this.capitalizeFirstLetter(menuKey));
    },

    capitalizeFirstLetter(string) {
      if (!string) return "";
      return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },

    changePaymentInfoDependOnSelectedPaymentType(type) {
      const paymentStatus = this.form.getFieldValue("paymentStatus");
      const paymentMethod = this.form.getFieldValue("paymentMethod");
      const rate = this.form.getFieldValue("rate");

      switch (type) {
        case PaymentType.EMPLOYEE:
          if (!paymentStatus && this.form.getFieldValue("paymentStatus")) {
            this.form.setFieldsValue({
              paymentStatus: PaymentStatus.FULL_TIME,
            });
          }
          if (!paymentMethod && this.form.getFieldValue("paymentMethod")) {
            this.form.setFieldsValue({
              paymentMethod: PaymentMethod.SALARY,
            });
          }
          if (rate > 0 && this.form.getFieldValue("rate")) {
            this.form.setFieldsValue({
              rate: 0,
            });
          }
          break;
        case PaymentType.CONTRACTOR:
          if (!paymentStatus && this.form.getFieldValue("paymentStatus")) {
            this.form.setFieldsValue({
              paymentStatus: PaymentStatus.FULL_TIME,
            });
          }
          if (this.form.getFieldValue("paymentMethod")) {
            this.form.setFieldsValue({
              paymentMethod: PaymentMethod.HOURLY,
            });
          }
          break;
        default:
          if (
            this.form.getFieldValue("paymentStatus") &&
            this.form.getFieldValue("paymentMethod") &&
            this.form.getFieldValue("rate")
          ) {
            this.form.setFieldsValue({
              paymentStatus: undefined,
              paymentMethod: undefined,
              rate: 0,
            });
          }
      }
    },
    setPaymentTypeBooleans(type) {
      switch (type) {
        case PaymentType.EMPLOYEE:
          this.isEmployee = true;
          this.isContractor = false;
          break;
        case PaymentType.CONTRACTOR:
          this.isEmployee = false;
          this.isContractor = true;
          break;
        default:
          this.isEmployee = false;
          this.isContractor = false;
      }
    },
    setSelectedAgency(legalEntity) {
      this.selectedAgency = {
        ...legalEntity,
      };
    },

    async loadData() {
      try {
        this.loading = true;

        const [associateResponse, legalEntitiesResponse] = await Promise.all([
          this.apiGetAssociate(this.associateNumber),
          this.apiGetLegalEntities(),
        ]);

        const fields = associateResponse.data;
        this.agencies = legalEntitiesResponse.body.legal_entities;

        return fields;
      } catch (error) {
        notification["error"]({
          message: "Error",
          description:
            "An error occurred while loading associate data. Please try again",
        });
        this.$emit("close");

        return null;
      } finally {
        this.loading = false;
      }
    },

    async fetchDataAndInitForm() {
      try {
        const fields = await this.loadData();

        if (!fields) return;

        this.initializeFields(fields);
        this.setupForm(fields);

        await Promise.all([this.loadDocuments()]);
      } catch (err) {
        console.error(err);
      }
    },

    initializeFields(fields) {
      this.fieldsTouched = false;
      this.setPaymentTypeBooleans(fields.payment_type);

      this.associateId = fields.id;
      this.isIndependentContractor = fields.is_independent_contractor;
      this.selectedAuthTypes = fields.available_auth_types;
      this.selectedPermissions = fields.permissions;

      if (fields.legal_entity) {
        this.setSelectedAgency(fields.legal_entity);
      }

      this.kpiStats = fields.kpi;
      this.paymentStatus = fields.pay_status;
      this.paymentMethod = fields.pay_method;
    },

    async loadDocuments() {
      try {
        const { body } = await this.apiGetSignedDocuments(this.associateId);

        if (body.error_code && body.error_code !== "0") {
          notification["warning"]({
            message: "Error",
            description:
              "An error occurred while sending associate to onboarding. Try again later",
          });
          return;
        }

        this.documents = body.items;
        this.updateDocumentFilters(this.documents);
      } catch (err) {
        console.error(err);
      }
    },

    updateDocumentFilters(documents) {
      documents.forEach((item) => {
        let filters = this.columns.find((col) => col.key === "name").filters;
        if (!filters.find((el) => el.value === item.name)) {
          filters.push({ text: item.name, value: item.name });
        }

        filters = this.columns.find((col) => col.key === "type").filters;
        if (!filters.find((el) => el.value === item.type)) {
          filters.push({
            text: item.type === "text" ? "Text" : "PDF",
            value: item.type,
          });
        }

        if (item.group != null) {
          filters = this.columns.find((col) => col.key === "group").filters;
          if (!filters.find((el) => el.value === item.group)) {
            filters.push({ text: item.group, value: item.group });
          }
        }
      });
    },

    setupForm(fields) {
      this.formFieldsBackup = fields;
      this.form = this.$form.createForm(this, {
        name: "associate_profile_edit_form",
        onFieldsChange: (_, changedFields) => {
          this.fieldsTouched = true;
          if (changedFields.paymentType) {
            this.handlePaymentTypeChange(changedFields.paymentType.value);
          }
          if (changedFields.projectRole) {
            this.handleRoleChange(changedFields.projectRole.value);
          }
        },
        mapPropsToFields: () => this.mapFieldsToForm(fields),
      });

      this.isVerified = fields.is_verified ?? true;
      this.isActiveAssociate = fields.is_active;

      if (!this.isActiveAssociate || !this.isVerified) {
        this.activeTab = [MenuOptions.PERSONAL];
        if (!this.isVerified) this.startEdit();
      }
    },

    mapFieldsToForm(fields) {
      return {
        projectRole: this.$form.createFormField({ value: fields.role_id }),
        scheduleType: this.$form.createFormField({
          value: fields.schedule_type,
        }),
        firstName: this.$form.createFormField({ value: fields.first_name }),
        lastName: this.$form.createFormField({ value: fields.last_name }),
        email: this.$form.createFormField({ value: fields.email }),
        phone: this.$form.createFormField({ value: fields.phone }),
        associateNumber: this.$form.createFormField({
          value: this.associateNumber,
        }),
        socialSecurityNumber: this.$form.createFormField({
          value: fields.social_security_number,
        }),
        birthDate: this.$form.createFormField({
          value: fields.birth_date ? moment(fields.birth_date) : undefined,
        }),
        address: this.$form.createFormField({ value: fields.address_line1 }),
        city: this.$form.createFormField({ value: fields.city }),
        state: this.$form.createFormField({
          value: fields.state ? fields.state.toUpperCase() : "CA",
        }),
        country: this.$form.createFormField({
          value: fields.country ? fields.country.toUpperCase() : "US",
        }),
        zipcode: this.$form.createFormField({ value: fields.zipcode }),
        paymentType: this.$form.createFormField({ value: fields.payment_type }),
        rate: this.$form.createFormField({
          value: Util.convertDbToMoney(fields.rate),
        }),
        suspension: this.$form.createFormField({ value: fields.suspension }),
        termination: this.$form.createFormField({ value: fields.termination }),
        shiftActionAutocomplete: this.$form.createFormField({
          value: fields.shift_action_autocomplete,
        }),
        requireShiftConfirmation: this.$form.createFormField({
          value: fields.require_shift_confirmation,
        }),
        autoApprovePaymentRequest: this.$form.createFormField({
          value: fields.auto_approve_payment_request,
        }),
        timeTrackingOnTerminal: this.$form.createFormField({
          value: fields.time_tracking_on_terminal,
        }),
        shiftsFromScheduler: this.$form.createFormField({
          value: fields.shifts_from_scheduler,
        }),
      };
    },

    async fetchOnboardingData() {
      try {
        const response = await this.apiGetOnboarding(this.associateId);
        if (response.data && response.data.onboarding) {
          this.onboardingInfo = response.data.onboarding;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async fetchDepartureData() {
      try {
        const response = await this.apiGetDeparture(this.associateId);
        if (response.data && response.data.departure) {
          this.departureInfo = response.data.departure;
        }
      } catch (err) {
        console.error(err);
      }
    },

    calculateDefaultDate() {
      return moment().subtract(18, "years").endOf("day");
    },
    disabledBirthDate(current) {
      const eighteenYearsAgo = moment().subtract(18, "years");
      return current && current > eighteenYearsAgo.endOf("day");
    },
    switchTab(tab) {
      if (
        !Object.values(MenuOptions).includes(tab) ||
        this.activeTab[0] == tab
      ) {
        return;
      }

      this.activeTab = [tab];
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async downloadDocument(document_id) {
      try {
        const payload = {
          document_id,
          associate_id: this.associateId,
        };

        const file_url = await this.downloadAssociateDocument(payload);

        window.open(file_url, "_blank");
      } catch {
        this.showNotification("error", "Error", error);
      }
    },

    async downloadAllDocuments() {
      try {
        const payload = {
          associate_id: this.associateId,
        };

        const file_url = await this.downloadAllAssociateDocuments(payload);

        window.open(file_url);
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async sendDocuments(email) {
      const payload = {
        associate_id: this.associateId,
        email,
      };

      if (this.sendDocumentId) {
        payload.document_id = this.sendDocumentId;
      }

      this.loadingSendDocument = true;

      try {
        const sendDocument = this.sendDocumentId
          ? this.sendAssociateDocument
          : this.sendAllAssociateDocument;

        await sendDocument(payload);

        this.showNotification(
          "success",
          "Success",
          "Document successfully sent to email"
        );

        this.closeSendDocumentsModal();
      } catch (error) {
        this.showNotification(
          "error",
          "Error",
          error.message || "An error occurred"
        );
      } finally {
        this.loadingSendDocument = false;
      }
    },

    handleFieldChange() {
      this.fieldsTouched = true;
    },

    switchEditingAssociate() {
      this.editingAssociate = !this.editingAssociate;
    },
    startEdit() {
      this.switchEditingAssociate();

      this.$nextTick(() => {
        this.form.setFields(this.mapFieldsToForm(this.formFieldsBackup));

        if (!this.isVerified) {
          this.form.validateFields((err, values) => {
            if (err) {
              this.handleFormError(Object.keys(err));
            }
          });
        }
      });
    },
    cancelEdit() {
      this.ptChangedNow = false;
      this.errors = [];
      this.switchEditingAssociate();
    },

    disabledStartDate(startValue) {
      const endValue = this.kpiTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.kpiFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    loadKpi() {
      this.loading = true;

      this.apiGetKpiData(
        this.associateNumber,
        this.kpiFromString,
        this.kpiToString
      )
        .then((response) => {
          if (!response.data.kpi) {
            notification["error"]({
              message: "KPI Load Error",
              description: "Please try again",
            });
            return;
          }

          this.kpiStats = response.data.kpi;
        })
        .finally(() => (this.loading = false));
    },

    emitCloneAssociate() {
      this.$emit("clone");
    },

    toggleCloneAssociateSuggestionModal(val) {
      this.openedCloneAssociateSuggestionModal = val;
    },

    cancelChangesAfterCloneAssociateModalOpen() {
      this.toggleCloneAssociateSuggestionModal(false);
      this.differentPropertyName = undefined;
      this.cancelEdit();
    },

    cloneAssociateFromSuggestionModal() {
      this.toggleCloneAssociateSuggestionModal(false);
      this.differentPropertyName = undefined;
      this.emitCloneAssociate();
    },

    openWriteupForm() {
      this.showWriteupForm = true;
    },
    closeWriteupForm() {
      this.showWriteupForm = false;
    },

    openWriteupHistory() {
      this.showHistoryWriteup = true;
    },

    closeWriteupHistory() {
      this.showHistoryWriteup = false;
    },

    openSendDocumentsModal(id) {
      this.visibleSendDocumentsModal = true;
      if (id) this.sendDocumentId = id;
    },

    closeSendDocumentsModal() {
      this.visibleSendDocumentsModal = false;
      this.sendDocumentId = undefined;
    },

    transformSequenceStepType(stepType) {
      return sequenceStepTypes[stepType] ?? stepType;
    },

    sendAssociateToOnboarding() {
      this.sendingToOnboarding = true;

      this.apiSendOnboarding(this.associateId)
        .then(({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            notification["warning"]({
              message: "Error",
              description:
                "An error occurred while sending associate to onboarding. Try again later",
            });
            return;
          }

          notification["success"]({
            message: "Associate successfully sent to onboarding",
          });
          this.onboardingInfo.completion_status = "Deprecated";
        })
        .catch(() => {
          notification["warning"]({
            message: "Error",
            description:
              "An error occurred while sending associate to onboarding. Try again later",
          });
        })
        .finally(() => {
          this.sendingToOnboarding = false;
        });
    },

    isPayrollKey(authKey) {
      return [
        "early_start_authorization",
        "late_end_authorization",
        "no_lunch_authorization",
        "no_second_lunch_authorization",
        "overtime_authorization",
        "early_second_lunch_end_authorization",
        "early_lunch_end_authorization",
        "no_actual_time_authorization",
      ].includes(authKey);
    },

    onSelectedAuthTypesChange(selectedRowKeys) {
      this.fieldsTouched = true;
      this.selectedAuthTypes = selectedRowKeys;
    },

    onPermissionsChange(permissions) {
      this.fieldsTouched = true;
      this.selectedPermissions = permissions;
    },
  },
  mounted() {
    if (this.associateNumber) {
      this.fetchDataAndInitForm();
    }
  },
};
</script>

<style scoped>
.profile-form-menu {
  text-align: left !important;
}

.with-shortened-margin {
  margin-left: 50px !important;
}

.view-button-group {
  margin-top: 15px;
  margin-left: 30px;
}

.documents {
  overflow: hidden;
  flex-grow: 1;
  padding: 10px 50px;
  text-align: left;
}

.content-view {
  position: relative;
}

.kpi-controls {
  padding: 10px 30px;
}

.ant-form-item {
  width: unset !important;
}

.subtype-selector {
  text-align: left;
  margin-left: 5px;
}

.subtype-selector-p .ant-form-item {
  width: unset;
}

.ant-radio-checked {
  margin-left: 5px;
}

.pay-block {
  max-width: unset;
}

.unverified {
  color: #ff4646;
}

.auth-types {
  padding-left: 30px;

  display: flex;
  flex-direction: column;
}

.menu-item-error {
  background-color: #ffe3e3 !important;
  color: #ff1818 !important;
}

.menu-item-error::after {
  border-right: 3px solid #ff1818 !important;
}
</style>

<style lang="css" scoped src="../assets/css/form-styles.css" />
