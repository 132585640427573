<template>
  <a-modal
    title="Filters"
    width="600px"
    v-model="isVisible"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <div class="grid">
      <b class="col-4">Date From</b>
      <a-date-picker
        class="col-8"
        :disabled-date="disabledStartDate"
        format="MM/DD/YYYY"
        placeholder="Start Date"
        style="flex: 1 1 0"
        v-model="dateFromProxy"
        valueFormat="YYYY-MM-DD"
      />

      <b class="col-4">Date To</b>
      <a-date-picker
        class="col-8"
        :disabled-date="disabledEndDate"
        format="MM/DD/YYYY"
        placeholder="End Date"
        style="flex: 1 1 0"
        v-model="dateToProxy"
        valueFormat="YYYY-MM-DD"
      />
      <b class="col-4">Associate</b>
      <a-select
        class="col-8"
        ref="associateSelect"
        v-model="filters.associateNumber"
        show-search
        :allowClear="true"
        placeholder="Associate"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        :default-active-first-option="false"
        :dropdown-match-select-width="false"
        :options="foundAssociates"
        :loading="loading"
        @search="fetchAssociates"
        @change="handleSearchAssociatesChange"
        @focus="handleFocusAssociates"
      />

      <b class="col-4">Shift Status</b>
      <a-select
        class="col-8"
        v-model="filters.status"
        :allow-clear="true"
        placeholder="Status"
      >
        <a-select-option
          v-for="(status, index) in shiftStatuses"
          :key="index"
          :value="status"
        >
          {{ getShiftStatusTagLabel(status) }}
        </a-select-option>
      </a-select>

      <b class="col-4">Job Classification</b>
      <a-select
        class="col-8"
        v-model="roleProxy"
        :dropdown-match-select-width="false"
        :allow-clear="true"
        placeholder="Job Classification"
      >
        <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
          {{ role.name }}
        </a-select-option>
      </a-select>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="primary" @click="apply"> Apply </a-button>
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import {
  shiftStatusEnum,
  paymentStatusEnum,
  getShiftStatusTagLabel,
} from "@/const";
import api from "@/api";
import { debounce } from "@/helpers/debounce";
import moment from "moment-timezone";

const filtersBaseState = Object.freeze({
  associateNumber: undefined,
  status: undefined,
  role: undefined,
  dateTo: undefined,
  dateFrom: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-date-picker": DatePicker,
  },
  mixins: [api],
  data() {
    return {
      filters: { ...filtersBaseState },
      foundAssociates: [],
      loading: false,
      searchUsersTimeout: null,
      isVisible: false,
      shiftStatuses: Object.values({
        ...shiftStatusEnum,
        ...paymentStatusEnum,
      }),
      roles: this.$store.state.applicationState.projectRoles,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
    dateFromProxy: {
      get() {
        return this.filters.dateFrom === undefined
          ? null
          : this.filters.dateFrom;
      },
      set(value) {
        this.filters.dateFrom = value === null ? undefined : value;
      },
    },
    dateToProxy: {
      get() {
        return this.filters.dateTo === undefined ? null : this.filters.dateTo;
      },
      set(value) {
        this.filters.dateTo = value === null ? undefined : value;
      },
    },
    roleProxy: {
      get() {
        return this.filters.role ? Number(this.filters.role) : undefined;
      },
      set(value) {
        this.filters.role = value;
      },
    },
  },
  watch: {
    visible: {
      handler(newValue) {
        this.isVisible = newValue;
      },
      immediate: true,
    },
    currentFilters: {
      handler(newValue) {
        this.filters = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    getShiftStatusTagLabel,
    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.$emit("apply", {});
      }
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    apply() {
      this.$emit("apply", this.filters);
    },
    handleSearchAssociatesChange(value) {
      this.filters.associateNumber = value;
      this.$refs.associateSelect.blur();
    },
    fetchAssociates: debounce(async function (value) {
      this.loading = true;

      try {
        const resp = await this.apiSearchAssociate(value);

        this.foundAssociates = resp.data.associate_list.map(
          ({ employee_id, first_name, last_name }) => ({
            label: `[${employee_id}] ${first_name} ${last_name}`,
            key: employee_id,
            value: employee_id,
          })
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    }, 1000),
    handleFocusAssociates() {
      if (!this.filters.associate && !this.foundAssociates) {
        this.fetchAssociates();
      }
    },
    disabledStartDate(current) {
      const endValue = this.filters.dateTo;
      if (!endValue) {
        return false;
      }

      return current && current.valueOf() > moment(endValue).valueOf();
    },

    disabledEndDate(current) {
      const startValue = this.filters.dateFrom;
      if (!startValue) {
        return false;
      }

      return current && current.valueOf() <= moment(startValue).valueOf();
    },
  },
  mounted() {
    this.fetchAssociates();
  },
};
</script>
