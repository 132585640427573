<template>
  <div>
    <h2 class="view-header">Total Hours Detail</h2>
    <div class="flex flex-column gap-2">
      <div class="flex flex-row align-items-center justify-content-end">
        <a-select
          v-model="selectedRole"
          placeholder="Job Classification"
          class="role-select"
          :allow-clear="true"
          :disabled="loading"
        >
          <a-select-option
            v-for="role in roles"
            :key="role.id"
            :value="role.id"
          >
            {{ role.name }}
          </a-select-option>
        </a-select>

        <a-date-picker
          v-model="dateFrom"
          :disabled-date="disabledStartDate"
          :disabled="loading"
          format="MM/DD/YYYY"
          placeholder="Start Date"
        />

        <a-date-picker
          v-model="dateTo"
          :disabled-date="disabledEndDate"
          :disabled="loading"
          format="MM/DD/YYYY"
          placeholder="End Date"
        />

        <a-button
          icon="file-pdf"
          :disabled="disabledExportButton"
          @click="printReport"
        >
          Export PDF
        </a-button>
      </div>

      <a-table :columns="columns" :data-source="reportData" :loading="loading">
        <span
          class="associate-name"
          slot="associateName"
          slot-scope="text, record"
        >
          {{ record.first_name + " " + record.last_name }}
        </span>
        <span slot="otherPcHours" slot-scope="text, record">
          <template v-if="record.other_pc && record.other_pc.length > 0">
            <div class="flex flex-column gap-1">
              <span v-for="otherPc in record.other_pc" :key="otherPc.code">
                {{ otherPc.description }}: {{ otherPc.hours }}
              </span>
            </div>
          </template>
          <template v-else>-</template>
        </span>
      </a-table>

      <vue-html-to-pdf
        v-if="reportData && reportData.length > 0"
        ref="htmlToPdf"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :filename="pdfFileNameToExport"
        :pdf-quality="2"
        :preview-modal="false"
        :manual-pagination="true"
      >
        <section slot="pdf-content">
          <total-hours-detail-report-pdf
            :report-data="reportData"
            :date-from="dateFromUiString"
            :date-to="dateToUiString"
            :role-name="selectedRoleName"
          />
        </section>
      </vue-html-to-pdf>
    </div>
  </div>
</template>
<script>
import {
  Select,
  Input,
  DatePicker,
  Table,
  Button,
  notification,
} from "ant-design-vue";
import VueHtml2Pdf from "vue-html2pdf";

import api from "@/api";
import store from "@/store";
import Util from "@/util";

import TotalHoursDetailReportPdf from "@/components/total-hours-detail-report-pdf.vue";

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-input": Input,
    "a-date-picker": DatePicker,
    "a-table": Table,
    "a-button": Button,
    "total-hours-detail-report-pdf": TotalHoursDetailReportPdf,
    "vue-html-to-pdf": VueHtml2Pdf,
  },
  mixins: [api],
  data() {
    return {
      dateFrom: undefined,
      dateTo: undefined,

      selectedRole: undefined,

      reportData: [],
      columns: [
        {
          title: "Associate Number",
          dataIndex: "associate_number",
          sorter: (a, b) =>
            Number(a.associate_number) - Number(b.associate_number),
        },
        {
          title: "Associate Name",
          scopedSlots: { customRender: "associateName" },
          sorter: (a, b) => {
            const aName = Util.combineAssociateName(a.first_name, a.last_name);
            const bName = Util.combineAssociateName(b.first_name, b.last_name);

            return aName == null
              ? -1
              : bName == null
              ? 1
              : aName.localeCompare(bName);
          },
        },
        {
          title: "Role",
          dataIndex: "role",
          sorter: (a, b) =>
            a.role == null
              ? -1
              : b.role == null
              ? 1
              : a.role.localeCompare(b.role),
        },
        {
          title: "Regular",
          dataIndex: "reg_hours",
          sorter: (a, b) => a.reg_hours - b.reg_hours,
        },
        {
          title: "1st OT",
          dataIndex: "first_overtime_hours",
          sorter: (a, b) => a.first_overtime_hours - b.first_overtime_hours,
        },
        {
          title: "2nd OT",
          dataIndex: "second_overtime_hours",
          sorter: (a, b) => a.second_overtime_hours - b.second_overtime_hours,
        },
        {
          title: "Work",
          dataIndex: "work_hours",
          sorter: (a, b) => a.work_hours - b.work_hours,
        },
        {
          title: "Holiday",
          dataIndex: "holiday_hours",
          sorter: (a, b) => a.holiday_hours - b.holiday_hours,
        },
        {
          title: "Vacation",
          dataIndex: "vacation_hours",
          sorter: (a, b) => a.vacation_hours - b.vacation_hours,
        },
        {
          title: "Sick",
          dataIndex: "sick_hours",
          sorter: (a, b) => a.sick_hours - b.sick_hours,
        },
        {
          title: "PTO",
          dataIndex: "pto_hours",
          sorter: (a, b) => a.pto_hours - b.pto_hours,
        },
        {
          title: "Other",
          key: "other_pc_hours",
          scopedSlots: { customRender: "otherPcHours" },
        },
      ],

      loading: false,
    };
  },
  watch: {
    dateFrom() {
      this.loadData();
    },
    dateTo() {
      this.loadData();
    },
    selectedRole() {
      this.loadData();
    },
  },
  computed: {
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    serverDateFormat() {
      return store.state.applicationState.serverDateFormat;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },

    dateFromString() {
      if (this.dateFrom) {
        return this.dateFrom.format(this.serverDateFormat);
      }

      return null;
    },
    dateToString() {
      if (this.dateTo) {
        return this.dateTo.format(this.serverDateFormat);
      }
      return null;
    },

    dateFromUiString() {
      if (this.dateFrom) {
        return this.dateFrom.format(this.visualDateFormat);
      }

      return null;
    },
    dateToUiString() {
      if (this.dateTo) {
        return this.dateTo.format(this.visualDateFormat);
      }
      return null;
    },
    selectedRoleName() {
      if (this.selectedRole) {
        const projectRoleObject = this.roles.find(
          (role) => role.id == this.selectedRole
        );

        return projectRoleObject.name;
      }

      return null;
    },
    disabledExportButton() {
      return this.loading || !this.reportData || this.reportData.length == 0;
    },

    pdfFileNameToExport() {
      return (
        "total-hours-detail-report-" +
        this.$store.state.applicationState.currentProject.name
      );
    },
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    reportLoadError() {
      notification.error({
        message: "Total Hours Detail Report Load Error",
        description: "Please try again later",
      });
    },

    loadData() {
      this.loading = true;
      this.apiGetTotalHoursDetailReport(
        this.dateFromString,
        this.dateToString,
        this.selectedRole
      )
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            this.reportLoadError();
            return;
          }

          this.reportData = data.report_data;
        })
        .catch((error) => {})
        .finally(() => (this.loading = false));
    },

    printReport() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
      this.$nextTick(() => {
        this.$refs.htmlToPdf.generatePdf();
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.associate-name {
  word-wrap: break-word;
}

.role-select {
  width: 160px;
}
</style>
