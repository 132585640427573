<template>
  <div>
    <h2 class="view-header">Signed Shifts Report</h2>

    <div class="content-view">
      <div class="content-view-block">
        <div>
          <h3 class="content-subheader">Date Range</h3>
          <p>
            <b>Date From</b>
            <a-date-picker
              v-model="dateFrom"
              format="MM/DD/YYYY"
              placeholder="Date"
              :disabled-date="disabledStartDate"
            />
          </p>
          <p>
            <b>Date To</b>
            <a-date-picker
              v-model="dateTo"
              format="MM/DD/YYYY"
              placeholder="Date"
              :disabled="disabledDateToPicker"
              :disabled-date="disabledEndDate"
            />
          </p>
        </div>
        <div>
          <h3 class="content-subheader">Personal Info</h3>
          <p>
            <b>Associate</b>
            <a-radio-group v-model="associateFilterValue">
              <a-radio-button :value="associateFilterType.ALL"
                >All</a-radio-button
              >
              <a-radio-button :value="associateFilterType.SPECIFIC"
                >Specific</a-radio-button
              >
            </a-radio-group>
          </p>
          <template v-if="specificAssociate">
            <p>
              <b>Associate</b>
              <a-select
                show-search
                :allowClear="true"
                placeholder="Associate (Name/ID)"
                style="min-width: 180px"
                :filter-option="false"
                :not-found-content="null"
                :dropdown-match-select-width="false"
                :disabled="loading"
                :options="foundAssociates"
                @search="fetchUsers"
                @change="handleSearchUsersChange"
              />
            </p>
            <p v-if="selectedAssociateNumber">
              <b>Associate ID</b>
              {{ selectedAssociateNumber }}
            </p>
          </template>
          <template v-else>
            <p>
              <b>Job Classification</b>
              <a-select
                v-model="selectedRole"
                class="role-select"
                placeholder="Select J. C."
                :allowClear="true"
              >
                <a-select-option
                  v-for="role in roles"
                  :key="role.id"
                  :value="role.id"
                >
                  {{ role.name }}
                </a-select-option>
              </a-select>
            </p>
          </template>
          <p>
            <b>Report Type</b>
            <a-radio-group v-model="selectedReportType">
              <a-radio :value="reportType.DEFAULT">Default</a-radio>
              <a-radio :value="reportType.OFFICIAL">Official</a-radio>
            </a-radio-group>
          </p>
        </div>
        <div>
          <a-button
            type="primary"
            size="large"
            :disabled="disabledDownload"
            :loading="loading"
            @click="initReportDownloadProcess"
          >
            Download
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  List,
  Modal,
  notification,
  Radio,
  Select,
  Switch,
} from "ant-design-vue";
import api from "@/api";
import moment from "moment-timezone";

export default {
  components: {
    "a-input": Input,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-date-picker": DatePicker,

    "a-button": Button,

    "a-radio": Radio,
    "a-radio-group": Radio.Group,
    "a-radio-button": Radio.Button,

    "a-switch": Switch,

    "a-checkbox": Checkbox,

    "a-list": List,
    "a-list-item": List.Item,

    "a-modal": Modal,
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,

      role: null,
      isActive: null,

      selectedAssociateNumber: null,

      associateFilterValue: 0,
      selectedStatus: 0,

      roles: [],
      selectedRole: undefined,

      loading: false,
      foundAssociates: [],
      searchUsersTimeout: 0,

      associateFilterType: {
        ALL: 0,
        SPECIFIC: 1,
      },
      reportType: {
        DEFAULT: 0,
        OFFICIAL: 1,
      },

      selectedReportType: 0,

      serverDateFormat: "YYYY-MM-DD",
    };
  },
  mixins: [api],
  watch: {
    associateFilterValue(newVal) {
      if (newVal !== this.associateFilterType.SPECIFIC) {
        this.clearAssociateInfo();
      }
    },
  },
  computed: {
    specificAssociate() {
      return this.associateFilterValue === this.associateFilterType.SPECIFIC;
    },
    dateFromString() {
      return this.dateFrom
        ? moment(this.dateFrom).format(this.serverDateFormat)
        : null;
    },
    dateToString() {
      return this.dateTo
        ? moment(this.dateTo).format(this.serverDateFormat)
        : null;
    },
    disabledDownload() {
      return (
        !this.dateTo ||
        !this.dateFrom ||
        !(this.selectedAssociateNumber || this.selectedRole)
      );
    },
    disabledDateToPicker() {
      return !this.dateFrom;
    },
  },
  methods: {
    loadRoles() {
      this.apiGetNotAdmRoles().then(
        (response) => (this.roles = response.body.roles)
      );
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      const threeMonthsAgo = moment().subtract(3, "months");

      const isBeforeThreeMonths = startValue.isBefore(threeMonthsAgo, "day");
      const isAfterToday = startValue.isAfter(moment(), "day");

      if (!startValue || !endValue) {
        return isBeforeThreeMonths || isAfterToday;
      }

      return (
        startValue.valueOf() > endValue.valueOf() ||
        isBeforeThreeMonths ||
        isAfterToday
      );
    },

    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      const maxEndDate = this.dateFrom
        ? moment(this.dateFrom).add(3, "months")
        : moment().add(3, "months");

      const isAfterMaxDate = endValue.isAfter(maxEndDate, "day");
      const isAfterToday = endValue.isAfter(moment(), "day");

      if (!endValue || !startValue) {
        return isAfterToday;
      }

      return (
        startValue.valueOf() >= endValue.valueOf() ||
        isAfterMaxDate ||
        isAfterToday
      );
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.apiSearchAssociate(value)
          .then((resp) => {
            this.foundAssociates = resp.data.associate_list.map((user) => ({
              label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
              key: user.employee_id,
              value: user.employee_id,
            }));
          })
          .catch((err) => console.error(err));
      }, 1000);
    },

    initReportDownloadProcess() {
      Modal.confirm({
        title: "Download Signed Shifts Report?",
        content: "It could take a few seconds",
        onOk: () => {
          this.downloadReport();
        },
        onCancel() {},
      });
    },

    async downloadReport() {
      this.loading = true;
      await this.apiGetSignedShiftsReport(
        this.dateFromString,
        this.dateToString,
        this.selectedAssociateNumber,
        this.selectedRole,
        this.selectedReportType
      )
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0" && !data.report_url) {
            this.showErrorNotification(data.msg);
          } else {
            window.open(data.report_url, "_blank");
          }
        })
        .catch(() => this.showErrorNotification())
        .finally(() => (this.loading = false));
    },

    showErrorNotification(msg) {
      notification["warning"]({
        message: "Signed Shifts Report Download Error",
        description: msg ?? "Please try again later",
      });
    },

    handleSearchUsersChange(value) {
      this.selectedAssociateNumber = value;
    },

    clearAssociateInfo() {
      this.selectedAssociateNumber = undefined;
      this.selectedRole = undefined;
    },
  },
  mounted() {
    this.loadRoles();
    window.onbeforeunload = () => {
      if (this.loading) {
        return null;
      }
    };
  },
};
</script>

<style scoped>
.ant-checkbox-group {
  width: auto;
}

.role-select {
  width: 200px;
}
</style>
