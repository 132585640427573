<template>
  <a-modal
    title="Add Pay Code"
    :visible="visible"
    :footer="null"
    @cancel="closePayCodeModal"
  >
    <a-form-model
      layout="horizontal"
      :model="payCode"
      :rules="rules"
      ref="form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Name"
        prop="name"
      >
        <a-input v-model="payCode.name" placeholder="Enter name" />
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Description"
        prop="description"
      >
        <a-input
          v-model="payCode.description"
          placeholder="Enter description"
        />
      </a-form-model-item>

      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Type"
        prop="type"
      >
        <a-select v-model="payCode.type" placeholder="Enter type">
          <a-select-option value="duration">Duration</a-select-option>
          <a-select-option value="total">Total</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        :colon="false"
        label-align="left"
        :label="`Value, ${payCode.type === 'total' ? '$' : 'hours'}`"
        prop="value"
      >
        <a-input-number
          v-model="payCode.value"
          :min="0"
          placeholder="Enter value"
        />
      </a-form-model-item>

      <template v-if="payCode.type === 'duration'">
        <a-alert
          message="Allow specific pay code's duration with a duration longer than 24 hours."
          type="info"
          show-icon
        />

        <a-form-model-item
          :colon="false"
          label-align="left"
          label="Unlimited Duration"
          prop="unlimited_duration"
        >
          <a-switch v-model="payCode.unlimited_duration" />
        </a-form-model-item>
      </template>

      <a-alert
        message="Make sure pay code can't be created if the associate has already worked a shift on that day."
        type="info"
        show-icon
      />

      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Conflicted"
        prop="conflicted"
      >
        <a-switch v-model="payCode.conflicted" />
      </a-form-model-item>

      <a-alert
        message="Pay code can only be attached for a worked shift. It will be paid together with the shift."
        type="info"
        show-icon
      />

      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Attached"
        prop="attached"
      >
        <a-switch v-model="payCode.attached" />
      </a-form-model-item>
    </a-form-model>
    <div class="flex align-items-center justify-content-end gap-2">
      <a-button @click="closePayCodeModal">Cancel</a-button>
      <a-button type="primary" @click="submit" :loading="loading">
        Save
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import {
  Switch,
  Input,
  Select,
  InputNumber,
  Button,
  notification,
  FormModel,
  Alert,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers("paycode");

const initialPayCode = {
  name: undefined,
  description: undefined,
  type: "total",
  value: 0,
  conflicted: true,
  unlimited_duration: false,
  attached: false,
};

export default {
  components: {
    "a-switch": Switch,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-alert": Alert,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    payCodeId: {
      type: Number,
      default: undefined,
    },
  },
  watch: {
    editingPayCode(newVal) {
      this.payCode = { ...(newVal ?? initialPayCode) };
    },
    payCodeId(newVal) {
      if (newVal) {
        this.fetchPayCodeInfo();
      }
    },
  },
  data() {
    return {
      payCode: { ...(this.editingPayCode ?? initialPayCode) },

      rules: {
        name: [
          {
            required: true,
            message: "Please input name",
            trigger: "change",
          },
        ],
        description: [
          {
            required: true,
            message: "Please input description",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Please input type",
            trigger: "change",
          },
        ],
        value: [
          {
            required: true,
            message: "Please input value",
            trigger: "change",
          },
        ],
      },

      loading: false,
    };
  },
  computed: {
    ...mapState({
      editingPayCode: (state) => state.payCode,
    }),
  },
  methods: {
    ...mapActions(["createPayCode", "updatePayCode", "fetchPayCode"]),

    closePayCodeModal() {
      this.$emit("update:visible", false);
      this.$emit("update:payCodeId", undefined);
      this.$refs.form.resetFields();
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchPayCodeInfo() {
      try {
        this.fetchPayCode({ pay_code_id: this.payCodeId });
      } catch {
        this.showNotification("error", "Error", error);
      }
    },

    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        try {
          this.loading = true;

          this.editingPayCode
            ? await this.updatePayCode({ pay_code: this.payCode })
            : await this.createPayCode({ pay_code: this.payCode });

          this.showNotification(
            "success",
            "Info",
            `Pay code has been ${
              this.editingPayCode ? "edited" : "created"
            } successfully`
          );

          this.closePayCodeModal();
        } catch (error) {
          this.showNotification("error", "Error", error);
        } finally {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.ant-row {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
</style>
