<template>
  <a-modal
    title="Pay Code Deletion"
    v-model="visible"
    :closable="false"
    @cancel="closeDeletionModal"
    :destroy-on-close="true"
  >
    <div>
      <span> Are you sure you want to delete pay code #{{ payCodeId }}? </span>
    </div>
    <template slot="footer">
      <a-button @click="closeDeletionModal">Cancel</a-button>
      <a-button type="danger" @click="submit" :loading="loading"
        >Delete Pay Code</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { Button, Modal, notification } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers("paycode");

export default {
  components: {
    "a-button": Button,
    "a-modal": Modal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    payCodeId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["deletePayCode"]),

    closeDeletionModal() {
      this.$emit("update:visible", false);
      this.$emit("update:payCodeId", undefined);
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async submit() {
      try {
        this.loading = true;

        await this.deletePayCode({ pay_code_id: this.payCodeId });

        this.showNotification(
          "success",
          "Info",
          `Pay code has been deleting successfully`
        );

        this.closeDeletionModal();
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
