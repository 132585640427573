import apiClient from "@/services/api";

export default {
  async getPayments({ commit }, { page = 1, size = 50, filters = {} }) {
    try {
      const { data } = await apiClient.post("api/get_admin_payment_requests", {
        page,
        size,
        filters,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the payment requests.Please try again later"
        );
      }

      commit("setPayments", { ...data, items: data.entities });
    } catch (error) {
      throw error.message;
    }
  },

  async getPayment({ commit }, { payment_id }) {
    try {
      const { data } = await apiClient.post("api/get_admin_payment_request", {
        payment_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the payment requests.Please try again later"
        );
      }

      commit("setPayment", data);
    } catch (error) {
      throw error.message;
    }
  },

  async denyPaymentRequest({ rootState }, { request_id, reason }) {
    try {
      const { data } = await apiClient.post("api/deny_payment_request", {
        project_id: rootState.applicationState.currentProject.id,
        request_id,
        reason,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while decline the payment request. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async approvePaymentRequest(_, { request_id, type }) {
    try {
      const { data } = await apiClient.post(
        `api/get_payment_requests/${request_id}/set_approve/${type}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while approve the payment request. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getPaymentSystems({ commit, rootState }) {
    try {
      const { data } = await apiClient.post(
        "/api/get_project_payment_systems",
        {
          project_id: rootState.applicationState.currentProject.id,
        }
      );
      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      const updatedSystems = data.payment_systems.filter(
        (item) => item.enabled
      );

      commit("setSystems", updatedSystems);
    } catch (error) {
      throw error.message;
    }
  },

  async getPaymentStatuses({ commit, rootState }) {
    try {
      const { data } = await apiClient.post(
        "/api/get_payment_requests_statuses",
        {
          project_id: rootState.applicationState.currentProject.id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setStatuses", data.statuses);
    } catch (error) {
      throw error.message;
    }
  },
};
