<template>
  <div>
    <h3>Activity Info</h3>
    <div class="grid" style="width: 400px">
      <template v-if="!isTerminated">
        <b class="col-4">Active</b>
        <div class="col-8">
          <a-switch :checked="isSwitchChecked" @change="openActivityConfirm" />
        </div>

        <b class="col-4">Author</b>
        <div class="col-8">{{ terminationAuthorName }}</div>

        <b class="col-4">Reason</b>
        <div class="col-8">{{ termination.reason }}</div>

        <b class="col-4">Terminated Date</b>
        <div class="col-8">
          {{ formatDateString(termination.termination_date) }}
        </div>

        <div class="col-12 flex gap-2">
          <a-button icon="redo" @click="openActivatedConfirm"
            >Activate</a-button
          >
        </div>
      </template>

      <template v-else>
        <div class="col-12 flex gap-2" v-if="!creatingTermination">
          <a-button @click="switchSetTermination" type="danger"
            >Create Termination</a-button
          >
        </div>

        <template v-else>
          <b class="col-4"
            >Terminated Date <span class="required-field">*</span></b
          >
          <div class="col-8">
            <a-date-picker
              v-model="newTermination.termination_date"
              placeholder="Enter termination date"
              class="w-full"
              :format="visualDateFormat"
            />
          </div>

          <b class="col-4">Reason <span class="required-field">*</span></b>
          <div class="col-8">
            <a-textarea
              v-model="newTermination.reason"
              :autoSize="true"
              placeholder="Enter termination reason"
            />
          </div>

          <div class="col-12 flex gap-2">
            <a-button
              type="primary"
              @click="openTerminationConfirm"
              :disabled="saveTerminationDisabled"
            >
              Terminate
            </a-button>
            <a-button type="danger" @click="cancelTermination">Cancel</a-button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Util from "@/util";
import api from "@/api";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  notification,
  Switch,
} from "ant-design-vue";
import moment from "moment-timezone";

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-textarea": Input.TextArea,
    "a-switch": Switch,
  },
  data() {
    return {
      creatingTermination: false,

      newTermination: {
        reason: undefined,
        termination_date: undefined,
      },

      isSwitchChecked: this.isActive,
    };
  },
  mixins: [api],
  props: {
    termination: {
      type: [Object, null],
      default: null,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    associateId: {
      type: Number,
      default: null,
    },
    associateNumber: {
      type: String,
      default: null,
    },
  },
  methods: {
    formatDateString(dateString) {
      return moment(dateString).format("MM/DD/YYYY");
    },
    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },
    switchSetTermination() {
      this.creatingTermination = !this.creatingTermination;
    },
    resetNewTermination() {
      this.newTermination = {
        termination_date: undefined,
        reason: undefined,
      };
    },
    cancelTermination() {
      this.resetNewTermination();
      this.switchSetTermination();
    },

    openActivatedConfirm() {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to activate an associate. If you are sure - click OK.`,
        onOk: async () => {
          await this.setActivity(true);
          await this.setActivation();
          this.$emit("reset");
        },
        onCancel() {},
      });
    },

    openTerminationConfirm() {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to terminate an associate. If you are sure - click OK.`,
        onOk: async () => {
          await this.setTermination();
          this.$emit("reset");
        },
        onCancel() {},
      });
    },

    openActivityConfirm(checked) {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to ${
          checked ? "activate" : "terminate"
        } an associate. ${
          checked
            ? "If you activate an associate, the termination will be canceled."
            : ""
        } If you are sure - click OK.`,
        onOk: async () => {
          await this.setActivity(checked);
          if (checked) {
            await this.setActivation();
          }
          this.$emit("reset");
        },
        onCancel() {},
      });
    },

    async setActivity(checked) {
      try {
        const { error_code } = await this.apiSetAssociateActivityStatus(
          this.associateNumber,
          checked ? "activate" : "terminate"
        );

        if (error_code && error_code !== "0") {
          this.showNotification(
            "error",
            "Activation Set Error",
            "Please try again later"
          );
        }

        this.showNotification(
          "success",
          "Activation Set",
          `Associate has been successfully ${
            checked ? "terminated" : "activated"
          }`
        );

        this.isSwitchChecked = checked;
      } catch (error) {
        this.showNotification(
          "error",
          "Activation Set Error",
          "An unexpected error occurred"
        );
      }
    },

    async setTermination() {
      try {
        const { data } = await this.apiSetTermination(
          this.associateId,
          this.newTermination
        );

        if (data.error_code && data.error_code !== "0") {
          this.showNotification(
            "error",
            "Termination Set Error",
            "Please try again later"
          );
          return;
        }

        this.showNotification(
          "success",
          "Termination Set",
          `Associate is now terminated`
        );

        this.resetNewTermination();
        this.switchSetTermination();
      } catch (error) {
        this.showNotification(
          "error",
          "Termination Set Error",
          "An unexpected error occurred"
        );
      }
    },

    async setActivation() {
      try {
        const { data } = await this.apiCancelTermination(this.associateId);

        if (data.error_code && data.error_code !== "0") {
          this.showNotification(
            "error",
            "Termination Release Error",
            "Please try again later"
          );
          return;
        }

        this.showNotification(
          "success",
          "Termination canceled",
          `Associate is now not terminated`
        );
      } catch (error) {
        this.showNotification(
          "error",
          "Termination Release Error",
          "An unexpected error occurred"
        );
      }
    },
  },
  computed: {
    isTerminated() {
      return !this.termination;
    },
    terminationAuthorName() {
      if (this.isTerminated) {
        return "-";
      }

      const suspensionAuthor = this.termination.author;
      return Util.combineAssociateName(
        suspensionAuthor.first_name,
        suspensionAuthor.last_name
      );
    },
    saveTerminationDisabled() {
      return (
        !this.newTermination.termination_date ||
        !this.newTermination.reason ||
        this.newTermination.reason.length === 0
      );
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
  },
};
</script>
