<template>
  <div class="associate-info">
    <user-avatar :width="32" :height="32" :image-path="photoUrl ? photoUrl + '?t=' + randomQueryParam : undefined" />
    <div class="associate-name">
      <template v-if="role">
        <div>{{ titledFirstName + ' ' + titledLastName }}</div>
        <div>Job Classification: <b>{{ role }}</b></div>
      </template>
      <template v-else>
        <div>
          {{ titledFirstName }}
        </div>
        <div>
          {{ titledLastName }}
        </div>
      </template>
      <div v-if="associateNumber" class="associate-number">
        № {{ associateNumber }}
      </div>
      <div class="unverified" v-if="unverified">
        Unverified
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/user-avatar.vue";
import Util from "@/util";

export default {
  components: {
    'user-avatar': UserAvatar
  },
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    photoUrl: {
      type: String,
      required: false
    },
    role: {
      type: String,
      required: false
    },
    unverified: {
      type: Boolean,
      required: false
    },
    associateNumber: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    titledFirstName() {
      return Util.titleCase(this.firstName);
    },
    titledLastName() {
      return Util.titleCase(this.lastName);
    },
    randomQueryParam() {
      return new Date().getTime() + '_' + Math.random().toString(36).substr(2, 9);
    }
  }
}
</script>

<style scoped>
.associate-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.associate-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.associate-number {
  font-style: italic;
  font-size: 0.8em;
}

.unverified {
  color: #ff4646;
}
</style>
